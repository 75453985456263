<div class="container">
    <div class="header-informations">
        <h1>
            Ne plus recevoir les emails de MyNoty
        </h1>
        <hr>
    </div>

    <mat-card>
        <h3>
            {{currentClient.identity.convivialName}}

            <a routerLink="/results" class="to-results">Retour aux résultats</a>
        </h3>

        <h4>
            Vous recevez des emails de MyNoty car {{currentClient.demandeur.responsableDossier.convivialName}}
            ({{currentClient.demandeur.responsableDossier.site.etude.raisonSociale}}) vous a proposé des biens immobiliers
            correspondant à votre recherche.
        </h4>

        <p>
            En cliquant sur le bouton ci-dessous, vous ne recevrez plus d'emails de MyNoty.
        </p>

        <p>
            Vous conservez malgré tout votre espace afin de consulter les biens sélectionnés par
            {{currentClient.demandeur.responsableDossier.convivialName}}.
        </p>

        <div [ngSwitch]="unsubscribed" class="unsubscribe">
            <button *ngSwitchDefault type="button" (click)="unsubscribe()" mat-raised-button color="warn"
                    class="mat-raised-button mat-warn">Ne plus recevoir les emails
            </button>

            <div class="spinner"><mat-spinner *ngSwitchCase="'INPROGRESS'"></mat-spinner></div>

            <p *ngSwitchCase="'DONE'" class="unsubscribed">
                Vous ne recevrez plus d'emails de la part de
                {{currentClient.demandeur.responsableDossier.convivialName}}
                ({{currentClient.demandeur.responsableDossier.site.etude.raisonSociale}}) concernant MyNoty.
            </p>
        </div>
    </mat-card>
</div>

<app-footer [user]="currentClient.demandeur.responsableDossier" *ngIf="currentClient"></app-footer>
