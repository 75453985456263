import AOldDossier from '@app/old_class/dossier.abstract';
import DictionaryItem from '@models/dictionary/item/dictionary-item.model';
import {IDossierBien} from '@models/bien/bien.interfaces';
import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';

// @todo Supprimer l'utilisation de AOldDossier
// @todo Valider l'utilisation de tous les attributs
export default class Vente extends AOldDossier implements IDossierBien {
    static readonly TYPE_TRADITIONNELLE: DictionaryItem = {abreviation: 'VT', code: 'traditionnelle', label: 'Traditionnelle'} as DictionaryItem;
    static readonly TYPE_INTERACTIVE: DictionaryItem = {abreviation: 'VI', code: 'interactive', label: 'Interactive'} as DictionaryItem;
    static readonly TYPE_VIAGER: DictionaryItem = {abreviation: 'VV', code: 'viager', label: 'Viager'} as DictionaryItem;

    private _baissePrix!: boolean;
    private _baissePrixDate!: string;
    private _fraisActe!: number;
    private _honoraires!: number;
    private _interactiveDateFin!: string;
    private _interactivePasOffre!: number;
    private _interactivePremiereOffre!: number;
    private _interactiveSystemeEncheres!: string;
    private _pricing!: VentePricingAbstract;
    private _prix!: number;
    private _type!: string;
    private _typeCharges!: string;
    private _viagerBouquet!: number;
    private _viagerRente!: number;
    private _viagerRentePeriodicite!: string;
    private _viagerValeurBien!: number;

    get baissePrix(): boolean {
        return this._baissePrix;
    }

    set baissePrix(value: boolean) {
        this._baissePrix = value;
    }

    get baissePrixDate(): string {
        return this._baissePrixDate;
    }

    set baissePrixDate(value: string) {
        this._baissePrixDate = value;
    }

    get fraisActe(): number {
        return this._fraisActe;
    }

    set fraisActe(fraisActe: number) {
        this._fraisActe = fraisActe;
    }

    get honoraires(): number {
        return this._honoraires;
    }

    set honoraires(honoraires: number) {
        this._honoraires = honoraires;
    }

    get interactiveDateFin(): string {
        return this._interactiveDateFin;
    }

    set interactiveDateFin(value: string) {
        this._interactiveDateFin = value;
    }

    get interactivePasOffre(): number {
        return this._interactivePasOffre;
    }

    set interactivePasOffre(value: number) {
        this._interactivePasOffre = value;
    }

    get interactivePremiereOffre(): number {
        return this._interactivePremiereOffre;
    }

    set interactivePremiereOffre(value: number) {
        this._interactivePremiereOffre = value;
    }

    get interactiveSystemeEncheres(): string {
        return this._interactiveSystemeEncheres;
    }

    set interactiveSystemeEncheres(value: string) {
        this._interactiveSystemeEncheres = value;
    }

    get pricing(): VentePricingAbstract {
        return this._pricing;
    }

    set pricing(value: VentePricingAbstract) {
        this._pricing = value;
    }

    get prix(): number {
        return this._prix;
    }

    set prix(prix: number) {
        this._prix = prix;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get typeCharges(): string {
        return this._typeCharges;
    }

    set typeCharges(typeCharges: string) {
        this._typeCharges = typeCharges;
    }

    get typeDossier(): string {
        return 'vente';
    }

    get viagerBouquet(): number {
        return this._viagerBouquet;
    }

    set viagerBouquet(value: number) {
        this._viagerBouquet = value;
    }

    get viagerRente(): number {
        return this._viagerRente;
    }

    set viagerRente(value: number) {
        this._viagerRente = value;
    }

    get viagerRentePeriodicite(): string {
        return this._viagerRentePeriodicite;
    }

    set viagerRentePeriodicite(value: string) {
        this._viagerRentePeriodicite = value;
    }

    get viagerValeurBien(): number {
        return this._viagerValeurBien;
    }

    set viagerValeurBien(value: number) {
        this._viagerValeurBien = value;
    }
}
