<div *ngIf="vente.pricing.typeCharges === TYPE_CHARGES_NV">
  <p>
    Dont {{vente.pricing.priceNV | appCurrency}} de {{vente.pricing.TYPE_PRICE.label | lowercase}}
    {{TYPE_CHARGES_NV.label | lowercase}}
  </p>

  <p>Dont {{vente.pricing.honoraires | appCurrency}} d'honoraires de négociation</p>

  <p *ngIf="vente.pricing.pourcentageHonoraires > 0">
    (soit {{vente.pricing.pourcentageHonoraires | appCurrency: '%'}} T.T.C.
    <span *ngIf="vente.type !== TYPE_VIAGER.code">
      du {{vente.pricing.TYPE_PRICE.abreviation | lowercase}} {{TYPE_CHARGES_NV.label | lowercase}})
    </span>
    <span *ngIf="vente.type === TYPE_VIAGER.code">de la valeur du bien</span>
  </p>
</div>

<p *ngIf="vente.pricing.typeCharges === TYPE_CHARGES_HNI">{{vente.pricing.typeCharges.aCharge}}</p>

<p *ngIf="vente.pricing.fraisActe > 0">+ {{vente.pricing.fraisActe | appCurrency}} Frais d'acte</p>

<p *ngIf="vente.pricing.typeCharges === TYPE_CHARGES_NV">{{vente.pricing.typeCharges.aCharge}}</p>

<p *ngIf="vente.type === TYPE_INTERACTIVE.code">
  {{venteInteractivePricing.systemeEncheres.label}}

  <span *ngIf="venteInteractivePricing.pasOffre > 0">
    avec un pas de {{venteInteractivePricing.pasOffre | appCurrency}}
  </span>
</p>

<div *ngIf="vente.type === TYPE_VIAGER.code">
  <p *ngIf="venteViagerPricing.rente > 0">
    Avec une rente {{venteViagerPricing.rentePeriodicite | lowercase}} de {{venteViagerPricing.rente | appCurrency}}
  </p>

  <p *ngIf="venteViagerPricing.valeurBien > 0">Bien d'une valeur de {{venteViagerPricing.valeurBien | appCurrency}}</p>
</div>

<div *ngIf="showFinancement" class="financement"><a (click)="openDialog()">Financer ce bien</a></div>
