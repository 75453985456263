import {Injectable} from '@angular/core';
import {LocationFactory} from '@models/location/location.factory';
import Result from '@app/old_class/result';
import {IResultApi} from '@models/result/result.interfaces';
import {VenteFactory} from '@models/vente/vente.factory';

@Injectable({providedIn: 'root'})
export class ResultFactory {
    private _locationFactory: LocationFactory;
    private _venteFactory: VenteFactory;

    constructor(locationFactory: LocationFactory, venteFactory: VenteFactory) {
        this._locationFactory = locationFactory;
        this._venteFactory = venteFactory;
    }

    create(resultApi: IResultApi): Result {
        const result = new Result();

        result.id = resultApi.id;
        if (resultApi.location) {
            result.location = this._locationFactory.create(resultApi.location);
            result.dossierBien = result.location;
        } else if (resultApi.vente) {
            result.vente = this._venteFactory.create(resultApi.vente);
            result.dossierBien = result.vente;
        }

        return result;
    }
}
