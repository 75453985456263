export default class Financeur {
    private _cgu!: string;
    private _nom!: string;
    private _uuid: string;

    constructor(uuid: string) {
        this._uuid = uuid;
    }

    get cgu(): string {
        return this._cgu;
    }

    set cgu(value: string) {
        this._cgu = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
    }

    get uuid(): string {
        return this._uuid;
    }
}
