import {PhoneFactory} from '@core/models/phone/phone.factory';
import {Injectable} from '@angular/core';
import Person from '@core/models/person/person.model';
import {IPersonApi} from '@core/models/person/person.interfaces';

@Injectable({providedIn: 'root'})
export class PersonFactory {
    private _phoneFactory: PhoneFactory;

    constructor(phoneFactory: PhoneFactory) {
        this._phoneFactory = phoneFactory;
    }

    create(personApi: IPersonApi): Person {
        return this.populatePerson<Person>(new Person(), personApi);
    }

    populatePerson<T extends Person>(person: T, personApi: IPersonApi): T {
        person.email = personApi.email;
        person.mobile = this._phoneFactory.create(personApi.mobile);
        person.nom = personApi.nom;
        person.prenom = personApi.prenom;
        person.telephone = this._phoneFactory.create(personApi.telephone);
        person.titre = personApi.titre;

        return person;
    }
}
