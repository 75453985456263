import {Injectable} from '@angular/core';
import {FinanceurFactory} from '@models/financeurs/financeur/financeur.factory';
import {FinanceursApiService} from '@models/financeurs/financeurs.api.service';
import CFinanceurs from '@models/financeurs/collection/financeurs.collection.model';
import {Observable} from 'rxjs';
import {ICFinanceursApi} from '@models/financeurs/collection/financeurs.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CFinanceursFactory {
    private _financeurFactory: FinanceurFactory;
    private _financeursApiService: FinanceursApiService;

    constructor(financeurFactory: FinanceurFactory,
                financeursApiService: FinanceursApiService) {
        this._financeurFactory = financeurFactory;
        this._financeursApiService = financeursApiService;
    }

    create(cFinanceursApi: ICFinanceursApi): CFinanceurs {
        const cFinanceurs = new CFinanceurs();

        cFinanceurs.links = new Links(cFinanceursApi.links);
        cFinanceurs.page = cFinanceursApi.page;
        cFinanceurs.pages = cFinanceursApi.pages;
        cFinanceurs.perPage = cFinanceursApi.limit;
        cFinanceurs.total = cFinanceursApi.total;

        if (cFinanceursApi._embedded instanceof Object && cFinanceursApi._embedded.items instanceof Array) {
            cFinanceursApi._embedded.items.map(financeurApi => {
                if (financeurApi instanceof Object) {
                    cFinanceurs.results.push(this._financeurFactory.create(financeurApi));
                }
            });
        }

        return cFinanceurs;
    }

    get$(): Observable<CFinanceurs> {
        return this._financeursApiService.getCollection$().pipe(map(cFinanceursApi => this.create(cFinanceursApi)));
    }
}
