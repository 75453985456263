import {Injectable} from '@angular/core';
import DictionaryItem from '@models/dictionary/item/dictionary-item.model';
import {DictionaryItemFactory} from '@models/dictionary/item/dictionary-item.factory';
import {IDictionaryItemApi} from '@models/dictionary/item/dictionary-item.interfaces';

@Injectable({providedIn: 'root'})
export class DictionaryFactory {
    static DiagnosticStatutNonCommunique = {code: 'non_communique', label: 'Non communiqué'};
    private _DiagnosticStatuts: DictionaryItem[] = [];

    constructor(dictionaryItemFactory: DictionaryItemFactory) {
        [
            {code: 'disponible', label: 'Disponible'},
            {code: 'non_soumis', label: 'Non soumis'},
            DictionaryFactory.DiagnosticStatutNonCommunique,
            {code: 'blanc', label: 'Vierge'},
        ].forEach((diagnosticStatut: IDictionaryItemApi) => {
            this._DiagnosticStatuts.push(dictionaryItemFactory.create(diagnosticStatut));
        });
    }

    get DiagnosticStatuts(): DictionaryItem[] {
        return this._DiagnosticStatuts;
    }
}
