import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {DemandeEtudeFinancementComponent} from '@app/old_components/dialogs/demande-etude-financement/demande-etude-financement.component';
import Vente from '@models/vente/vente.model';
import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';
import VenteInteractivePricing from '@models/vente/pricing/vente-interactive.pricing.model';
import VenteViagerPricing from '@models/vente/pricing/vente-viager.pricing.model';

@Component({
    selector: 'app-vente-pricing',
    templateUrl: 'vente.pricing.component.html',
    styleUrls: ['vente.pricing.component.scss']
})
export class AppVentePricingComponent implements OnInit {
    readonly TYPE_CHARGES_HNI = VentePricingAbstract.TYPE_CHARGES_HNI;
    readonly TYPE_CHARGES_NV = VentePricingAbstract.TYPE_CHARGES_NV;
    readonly TYPE_INTERACTIVE = Vente.TYPE_INTERACTIVE;
    readonly TYPE_VIAGER = Vente.TYPE_VIAGER;

    private _authenticationService: AuthenticationService;
    private _matDialog: MatDialog;
    private _matSnackBar: MatSnackBar;
    private _showFinancement!: boolean;
    private _vente!: Vente;

    constructor(matDialog: MatDialog, matSnackBar: MatSnackBar, authenticationService: AuthenticationService) {
        this._matDialog = matDialog;
        this._matSnackBar = matSnackBar;
        this._authenticationService = authenticationService;
    }

    get showFinancement(): boolean {
        return this._showFinancement;
    }

    @Input()
    set vente(value: Vente) {
        this._vente = value;
    }

    get vente(): Vente {
        return this._vente;
    }

    get venteInteractivePricing(): VenteInteractivePricing{
        return this.vente.pricing as VenteInteractivePricing;
    }

    get venteViagerPricing(): VenteViagerPricing {
        return this.vente.pricing as VenteViagerPricing;
    }

    ngOnInit(): void {
        this._showFinancement = this._authenticationService.currentClient.demandeur.responsableDossier.site.etude.etudeFinancement;
    }

    openDialog(): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '700px';
        dialogConfig.data = {dossier: this.vente};

        this._matDialog.open(DemandeEtudeFinancementComponent, dialogConfig).afterClosed().subscribe((order: string) => {
            if (order !== 'send') {
                return;
            }

            this._matSnackBar.open('La demande a été transmise.', undefined, {duration: 2000});
        });
    }
}
