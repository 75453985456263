<div class="container">
  <div *ngIf="errorResult" class="error-result">
    Le bien que vous voulez voir n'est plus disponible à la vente.
  </div>

  <div *ngIf="!!resultsCollection" class="header-informations">
    <h1 i18n>
      {resultsCollection.total, plural,
        =0 {Aucun bien proposé }
        =1 {1 bien proposé }
        other {{{resultsCollection.total}} biens proposés }}
      par votre notaire
    </h1>
    <hr>
  </div>

  <section [ngSwitch]="!!resultsCollection">
    <div *ngSwitchCase="true" fxFlexFill
         fxLayout="row wrap"
         fxLayout.xs="column"
         fxLayoutGap="15px grid">
      <div *ngFor="let result of resultsCollection.collection"
           (click)="goToResult(result)"
           class="list-item" fxFlex.sm="50"
           fxFlex.md="33" fxFlex="25">
        <mat-card *ngIf="result.dossierBien">
          <mat-card-header>
            <mat-card-title>{{result.dossierBien.bien.natureLabel}}</mat-card-title>

            <mat-card-subtitle>
              <div class="ellipsis" [ngClass]="result.vente?.pricing.baissePrix ? 'smallest': ''">
                {{result.dossierBien.bien.ville.nom}}
              </div>
            </mat-card-subtitle>

            <div *ngIf="result.vente?.pricing.baissePrix" class="baisse-prix">
              <div class="baisse-prix-label">Baisse prix</div>

              <div class="baisse-prix-date">{{result.vente.pricing.baissePrixDate | date: 'shortDate'}}</div>
            </div>
          </mat-card-header>

          <img *ngIf="!!result.dossierBien.bien.photos[0]" mat-card-image
               [src]="result.dossierBien.bien.photos[0]+'?size=thumbnail'">
          <img *ngIf="!result.dossierBien.bien.photos[0]" mat-card-image [src]="urlMarianne">
          <mat-card-content>
            <app-dossier-bien-label-price [dossierBien]="result.dossierBien"></app-dossier-bien-label-price>

            <p class="description">{{result.dossierBien.descriptif}}</p>

            <div class="bien-info">
              <div fxFlexFill
                   fxLayout="row"
                   *ngIf="utils.showSectionForNature('bienInfo',result.dossierBien.bien.natureCode)">
                <div class="info-item" fxFlex="33">
                  <div>
                    <fa-icon icon="cube"></fa-icon>
                  </div>
                  <p>{{result.dossierBien.bien.nombrePieces || "N.C." }}</p>
                </div>
                <div class="info-item" fxFlex="33">
                  <div>
                    <fa-icon icon="bath"></fa-icon>
                  </div>
                  <p>{{result.dossierBien.bien.nombreSalleDeBain || "N.C."}}</p>
                </div>
                <div class="info-item" fxFlex="33">
                  <div>
                    <fa-icon icon="bed"></fa-icon>
                  </div>
                  <p>{{result.dossierBien.bien.nombreChambres || "N.C."}}</p>
                </div>
              </div>
            </div>
            <div fxFlexFill
                 fxLayout="row">
              <div fxFlex="50">
                <a class="text-strong">Voir plus</a>
              </div>
              <div fxFlex="50" class="text-right">
                <span>{{result.dossierBien.reference}}</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div *ngSwitchDefault class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  </section>
</div>
