export default class DictionaryItem {
    private _abreviation: string;
    private _code: string;
    private _label: string;

    get abreviation(): string {
        return this._abreviation;
    }

    set abreviation(value: string) {
        this._abreviation = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }
}
