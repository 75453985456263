import {Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {IDemandeurApi} from '@models/demandeur/demandeur.interfaces';

@Injectable({providedIn: 'root'})
export class DemandeurApiService {
    private _modelApiService: ModelApiService;

    constructor(modelApiService: ModelApiService) {
        this._modelApiService = modelApiService;
    }

    get$(id: number): Observable<IDemandeurApi> {
        return this._modelApiService.get$<IDemandeurApi>('/' + id.toString());
    }
}
