import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({templateUrl: 'dialog-images.component.html', styleUrls: ['dialog-images.component.scss']})
export class DialogImagesComponent {
    private readonly _images: string[];
    private _photosIdx = 2;

    constructor(
    @Inject(MAT_DIALOG_DATA) matDialogData: { images: string[]; photosIdx: number },
                             matDialogRef: MatDialogRef<DialogImagesComponent>
    ) {
        this._images = matDialogData.images;
        this._photosIdx = matDialogData.photosIdx;
        matDialogRef.beforeClosed().subscribe(() => matDialogRef.close(this._photosIdx));
    }

    get images(): string[] {
        return this._images;
    }

    get currentIndex(): number {
        return this._photosIdx;
    }

    getCurrentIndex(photosIdx: number): void {
        this._photosIdx = photosIdx;
    }
}
