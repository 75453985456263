import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from '@core/routing/services/authentication.service';

@Injectable({providedIn: 'root'})
export class RoutingService {
    private _router: Router;
    private _authenticationService: AuthenticationService;
    private _redirectionUrl!: string;
    private _errorLogin!: HttpErrorResponse;

    constructor(router: Router, authenticationService: AuthenticationService) {
        this._router = router;
        this._authenticationService = authenticationService;
    }

    get errorLogin(): HttpErrorResponse {
        if (typeof this._errorLogin !== 'object') {
            return {} as HttpErrorResponse;
        }

        return this._errorLogin;
    }

    goToStart(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this._redirectionUrl = state.url;

        return this._router.navigate(['start'], {queryParams: route.queryParams});
    }

    goToRedirection(): void {
        if (this._redirectionUrl) {
            this._router.navigateByUrl(this._redirectionUrl);
        } else if (this._authenticationService.isConnected()) {
            this._router.navigateByUrl('');
        } else {
            this.goToLogin();
        }

        this._redirectionUrl = undefined!;
    }

    goToLogin(error: HttpErrorResponse = null!): Promise<boolean> {
        this._errorLogin = error;
        this._authenticationService.signOut();

        return this._router.navigateByUrl('login');
    }
}
