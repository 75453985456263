import {Injectable} from '@angular/core';
import {ILocationApi} from '@models/location/location.interfaces';
import Location from '@models/location/location.model';
import {LocationPricingFactory} from '@models/location/pricing/location-pricing.factory';
import {BienFactory} from '@models/bien/bien.factory';

@Injectable({providedIn: 'root'})
export class LocationFactory {
    private _bienFactory: BienFactory;
    private _locationPricingFactory: LocationPricingFactory;

    constructor(bienFactory: BienFactory, locationPricingFactory: LocationPricingFactory) {
        this._bienFactory = bienFactory;
        this._locationPricingFactory = locationPricingFactory;
    }

    create(locationApi: ILocationApi): Location {
        const location = new Location(locationApi.uuid);

        location.dateDisponibilite = locationApi.dateDisponibilite;
        location.descriptif = locationApi.descriptif;
        location.meuble = locationApi.meuble;
        location.pricing = this._locationPricingFactory.createFromLocationApi(locationApi);
        location.reference = locationApi.reference;
        location.titre = locationApi.titre;
        if (locationApi.bien instanceof Object) {
            location.bien = this._bienFactory.create(locationApi.bien);
        }

        return location;
    }
}
