import {Component, EventEmitter, Input, Output} from '@angular/core';
import Location from '@models/location/location.model';
import {IDossierBien} from '@models/bien/bien.interfaces';

@Component({
    templateUrl: 'locaux-commerciaux-details.component.html',
    styleUrls: ['locaux-commerciaux-details.component.scss']
})
export class LocauxCommerciauxDetailsComponent {
    @Output() getPhotosIdx = new EventEmitter<number>();
    private _dossierBien!: IDossierBien;
    private _photosIdx = 0;

    get dossierBien(): IDossierBien {
        return this._dossierBien;
    }

    @Input()
    set dossierBien(value: IDossierBien) {
        this._dossierBien = value;
    }

    get location(): Location {
        return this._dossierBien as unknown as Location;
    }

    get photosIdx(): number {
        return this._photosIdx;
    }

    @Input()
    set photosIdx(value: number) {
        this._photosIdx = value;
    }

    getPhotosIdxCB(photosIdx: number): void {
        this.getPhotosIdx.emit(photosIdx);
    }
}
