import Site from '@core/models/site/site.model';
import Person from '@core/models/person/person.model';

// @todo Valider l'utilisation de tous les attributs
export default class User extends Person {
    private _site: Site;

    get site(): Site {
        return this._site;
    }

    set site(site: Site) {
        this._site = site;
    }
}
