import {Injectable} from '@angular/core';
import Bien from '@models/bien/bien.model';

@Injectable({providedIn: 'root'})
export class BienPerformanceEnergetiqueService {
    isDiagV1(bien: Bien): boolean {
        if (!bien.habitable) {
            return true;
        }

        if (bien.isInDOMTOM()) {
            return true;
        }

        return bien.performanceEnergetique.isV1ByDate();
    }

    isDiagV2(bien: Bien): boolean {
        return !this.isDiagV1(bien);
    }
}
