<div *ngIf="dossier.bien.pieces && dossier.bien.pieces.length > 0" class="pieces">
  <h2>Pièces</h2>

  <table class="pieces">
    <tbody>
    <ng-container *ngFor="let piece of dossier.bien.pieces">
      <tr>
        <td>{{piece.label}}</td>

        <td>
          <p *ngIf="piece.surface > 0">{{piece.surface | number}} m<sup>2</sup></p>

          <p *ngIf="!!piece.commentaire" [innerHTML]="piece.commentaire | newline" class="pieces-comments"></p>

          <p *ngIf="!!piece.etage">{{piece.etage}}</p>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
