import {Component, Input} from '@angular/core';
import {IDossierBien} from '@models/bien/bien.interfaces';

@Component({
    selector: 'app-batiment-dossier',
    templateUrl: 'batiment-dossier.component.html',
    styleUrls: ['batiment-dossier.component.scss']
})
export class BatimentDossierComponent {
    private _dossier!: IDossierBien;

    get dossier(): IDossierBien {
        return this._dossier;
    }

    @Input()
    set dossier(value: IDossierBien) {
        this._dossier = value;
    }
}
