import Commune from '@core/models/commune/commune.model';
import Piece from '@core/models/piece/piece.model';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';

// @todo Valider l'utilisation de tous les attributs
export default class Bien {
    private _accesPmr: boolean;
    private _anneeConstruction: number;
    private _etatLabel: string;
    private _exposition: string;
    private _habitable: boolean;
    private _huisseries: string[];
    private _materiauxCouverture: string[];
    private _natureCode: string;
    private _nombreChambres: number;
    private _nombrePieces: number;
    private _nombreSalleDeBain: number;
    private _performanceEnergetique!: BienPerformanceEnergetique;
    private _photos: string[] = [];
    private _pieces: Piece[] = [];
    private _risquesZone: boolean;
    private _surfaceBatie: number;
    private _surfaceCadastrale: number;
    private _surfaceHabitable: number;
    private _surfaceNonBatie: number;
    private _urlVisiteVirtuelle: string;
    private _ville: Commune;

    get accesPmr(): boolean {
        return this._accesPmr;
    }

    set accesPmr(value: boolean) {
        this._accesPmr = value;
    }

    get anneeConstruction(): number {
        return this._anneeConstruction;
    }

    set anneeConstruction(value: number) {
        this._anneeConstruction = value;
    }

    get etatLabel(): string {
        return this._etatLabel;
    }

    set etatLabel(value: string) {
        this._etatLabel = value;
    }

    get exposition(): string {
        return this._exposition;
    }

    set exposition(value: string) {
        this._exposition = value;
    }

    get habitable(): boolean {
        return this._habitable;
    }

    set habitable(value: boolean) {
        this._habitable = value;
    }

    get huisseries(): string[] {
        return this._huisseries;
    }

    set huisseries(value: string[]) {
        this._huisseries = value;
    }

    get materiauxCouverture(): string[] {
        return this._materiauxCouverture;
    }

    set materiauxCouverture(value: string[]) {
        this._materiauxCouverture = value;
    }

    get natureCode(): string {
        return this._natureCode;
    }

    set natureCode(value: string) {
        this._natureCode = value;
    }

    get natureLabel(): string {
        switch (this.natureCode) {
            case 'MAISON':
                return 'Maison';
            case 'APPARTEMENT':
                return 'Appartement';
            case 'IMMEUBLE':
                return 'Immeuble';
            case 'BUREAUX':
                return 'Bureaux';
            case 'LOCAUX_COMMERCIAUX':
                return 'Loc. Comm.';
            case 'GARAGE':
                return 'Garage';
            case 'TERRAIN_A_BATIR':
                return 'Terrain à bâtir';
            case 'AUTRE_TERRAIN':
                return 'Terrain';
        }

        return 'Autre';
    }

    get nombreChambres(): number {
        return this._nombreChambres;
    }

    set nombreChambres(value: number) {
        this._nombreChambres = value;
    }

    get nombrePieces(): number {
        return this._nombrePieces;
    }

    set nombrePieces(value: number) {
        this._nombrePieces = value;
    }

    get nombreSalleDeBain(): number {
        return this._nombreSalleDeBain;
    }

    set nombreSalleDeBain(value: number) {
        this._nombreSalleDeBain = value;
    }

    get performanceEnergetique(): BienPerformanceEnergetique {
        return this._performanceEnergetique;
    }

    set performanceEnergetique(value: BienPerformanceEnergetique) {
        this._performanceEnergetique = value;
    }

    get photos(): string[] {
        return this._photos;
    }

    set photos(value: string[]) {
        this._photos = value;
    }

    get pieces(): Piece[] {
        return this._pieces;
    }

    set pieces(value: Piece[]) {
        this._pieces = value;
    }

    get risquesZone(): boolean {
        return this._risquesZone;
    }

    set risquesZone(value: boolean) {
        this._risquesZone = value;
    }

    get surfaceBatie(): number {
        return this._surfaceBatie;
    }

    set surfaceBatie(value: number) {
        this._surfaceBatie = value;
    }

    get surfaceCadastrale(): number {
        return this._surfaceCadastrale;
    }

    set surfaceCadastrale(value: number) {
        this._surfaceCadastrale = value;
    }

    get surfaceHabitable(): number {
        return this._surfaceHabitable;
    }

    set surfaceHabitable(value: number) {
        this._surfaceHabitable = value;
    }

    get surfaceNonBatie(): number {
        return this._surfaceNonBatie;
    }

    set surfaceNonBatie(value: number) {
        this._surfaceNonBatie = value;
    }

    get urlVisiteVirtuelle(): string {
        return this._urlVisiteVirtuelle;
    }

    set urlVisiteVirtuelle(value: string) {
        this._urlVisiteVirtuelle = value;
    }

    get urlVisiteVirtuelleSanitize(): string {
        return ((this.urlVisiteVirtuelle.substr(0, 8) === 'https://')
        || (this.urlVisiteVirtuelle.substr(0, 7) === 'http://') ? '' : 'https://')
        + this.urlVisiteVirtuelle;
    }

    get ville(): Commune {
        return this._ville;
    }

    set ville(value: Commune) {
        this._ville = value;
    }

    isInDOMTOM(): boolean {
        if (!this.ville) {
            return false;
        }

        return !this.ville.isFranceMetropolitaine;
    }
}
