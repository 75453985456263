import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {Extras, User} from '@sentry/types';

// Ce service n'est pas testé car impossible de mocker Sentry ou d'espionner sans run une autre librairie.
// Penser à supprimer l'entrée dans "codeCoverageExclude" de "angular.json" si une solution est mise en place.
@Injectable()
export class ErrorSentryService {
    private _isInit = false;

    init(options: Sentry.BrowserOptions): void {
        options.integrations = [new Sentry.Integrations.TryCatch({XMLHttpRequest: false})];
        Sentry.init(options);
        this._isInit = true;
    }

    captureException(exception: Error | ErrorEvent, extra: Extras): void {
        if (!this._isInit) {
            return;
        }

        Sentry.captureException(exception, {extra});
    }

    setUser(user: User): void {
        if (!this._isInit) {
            return;
        }

        Sentry.setUser(user);
    }
}
