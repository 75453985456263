import {Injectable} from '@angular/core';
import {IClientApi} from '@core/models/client/client.interfaces';
import Client from '@core/models/client/client.model';
import {EntrepriseFactory} from '@core/models/entreprise/entreprise.factory';
import {PersonFactory} from '@core/models/person/person.factory';
import {IPersonApi} from '@core/models/person/person.interfaces';
import {IEntrepriseApi} from '@core/models/entreprise/entreprise.interfaces';

@Injectable({providedIn: 'root'})
export class ClientFactory {
    private _entrepriseFactory: EntrepriseFactory;
    private _personFactory: PersonFactory;

    constructor(entrepriseFactory: EntrepriseFactory, personFactory: PersonFactory) {
        this._entrepriseFactory = entrepriseFactory;
        this._personFactory = personFactory;
    }

    create(clientApi: IClientApi): Client {
        const client = new Client();

        // @todo Voir si possibilité de simplifier et faire un objet plat comme pour le retour de l'API
        if (clientApi.raisonSociale) {
            client.identity = this._entrepriseFactory.create(clientApi as IEntrepriseApi);
        } else {
            client.identity = this._personFactory.create(clientApi as IPersonApi);
        }

        return client;
    }
}
