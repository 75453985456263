import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import {ContactService} from '@app/old_services/contact.service';
import Client from '@core/models/client/client.model';

@Component({
    selector: 'app-unsubscribe',
    templateUrl: 'unsubscribe.component.html',
    styleUrls: ['unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
    private _authenticationService: AuthenticationService;
    private _contactService: ContactService;
    private _currentClient!: Client;
    private _unsubscribed!: string;

    constructor(authenticationService: AuthenticationService, contactService: ContactService) {
        this._authenticationService = authenticationService;
        this._contactService = contactService;
    }

    get currentClient(): Client {
        return this._currentClient;
    }

    get unsubscribed(): string {
        return this._unsubscribed;
    }

    ngOnInit(): void {
        this._unsubscribed = '';
        this._currentClient = this._authenticationService.currentClient;
    }

    unsubscribe(): void {
        this._unsubscribed = 'INPROGRESS';
        this._contactService.unsubscribe().subscribe(() => this._unsubscribed = 'DONE', () => this._unsubscribed = 'DONE');
    }
}
