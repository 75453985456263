import {Injectable} from '@angular/core';
import {IUserApi} from '@core/models/user/user.interfaces';
import User from '@core/models/user/user.model';
import {PhoneFactory} from '@core/models/phone/phone.factory';
import {SiteFactory} from '@core/models/site/site.factory';
import {PersonFactory} from '@core/models/person/person.factory';

@Injectable({providedIn: 'root'})
export class UserFactory extends PersonFactory {
    private _siteFactory: SiteFactory;

    constructor(phoneFactory: PhoneFactory, siteFactory: SiteFactory) {
        super(phoneFactory);
        this._siteFactory = siteFactory;
    }

    create(userApi: IUserApi): User {
        const user = this.populatePerson<User>(new User(), userApi);

        user.site = this._siteFactory.create(userApi.site);

        return user;
    }
}
