import {ILinksApi} from '@models/links/links.interfaces';

export default class Links {
    private readonly _first: string;
    private readonly _last: string;
    private readonly _next: string;
    private readonly _previous: string;
    private readonly _self: string;

    constructor(linksApi: ILinksApi) {
        this._first = linksApi?.first?.href;
        this._last = linksApi?.last?.href;
        this._next = linksApi?.next?.href;
        this._previous = linksApi?.previous?.href;
        this._self = linksApi?.self?.href;
    }

    get first(): string {
        return this._first;
    }

    get last(): string {
        return this._last;
    }

    get next(): string {
        return this._next;
    }

    get previous(): string {
        return this._previous;
    }

    get self(): string {
        return this._self;
    }
}
