import {Injectable} from '@angular/core';
import Vente from '@models/vente/vente.model';
import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';
import VenteInteractivePricing from '@models/vente/pricing/vente-interactive.pricing.model';
import VenteTraditionnellePricing from '@models/vente/pricing/vente-traditionnelle.pricing.model';
import VenteViagerPricing from '@models/vente/pricing/vente-viager.pricing.model';

@Injectable({providedIn: 'root'})
export class VentePricingFactory {
    createFromVente(vente: Vente): VentePricingAbstract {
        let ventePricing: VentePricingAbstract;

        if (vente.type === Vente.TYPE_INTERACTIVE.code) {
            const venteInteractivePricing = new VenteInteractivePricing();

            venteInteractivePricing.dateFin = new Date(vente.interactiveDateFin);
            venteInteractivePricing.pasOffre = vente.interactivePasOffre;
            venteInteractivePricing.premiereOffre = vente.interactivePremiereOffre;
            venteInteractivePricing.systemeEncheres = vente.interactiveSystemeEncheres === VenteInteractivePricing.SYST_ENCHERES_DESC.code ?
                VenteInteractivePricing.SYST_ENCHERES_DESC : VenteInteractivePricing.SYST_ENCHERES_ASC;
            vente.typeCharges = VentePricingAbstract.TYPE_CHARGES_HNI.code;
            ventePricing = venteInteractivePricing;
        } else if (vente.type === Vente.TYPE_TRADITIONNELLE.code) {
            const venteTraditionnellePricing = new VenteTraditionnellePricing();

            venteTraditionnellePricing.prixHNI = vente.prix + vente.honoraires;
            venteTraditionnellePricing.prixNV = vente.prix;
            ventePricing = venteTraditionnellePricing;
        } else if (vente.type === Vente.TYPE_VIAGER.code) {
            const venteViagerPricing = new VenteViagerPricing();

            venteViagerPricing.bouquetHNI = vente.viagerBouquet + vente.honoraires;
            venteViagerPricing.bouquetNV = vente.viagerBouquet;
            venteViagerPricing.valeurBien = vente.viagerValeurBien;
            venteViagerPricing.rente = vente.viagerRente;
            venteViagerPricing.rentePeriodicite = vente.viagerRentePeriodicite;
            ventePricing = venteViagerPricing;
        } else {
            throw new Error('Type de vente "' + vente.type + '" inconnu.');
        }

        ventePricing.baissePrix = vente.baissePrix;
        ventePricing.baissePrixDate = vente.baissePrixDate;
        ventePricing.fraisActe = vente.fraisActe;
        ventePricing.honoraires = vente.honoraires;
        ventePricing.typeCharges = vente.typeCharges === VentePricingAbstract.TYPE_CHARGES_NV.code ? VentePricingAbstract.TYPE_CHARGES_NV : VentePricingAbstract.TYPE_CHARGES_HNI;

        return ventePricing;
    }
}
