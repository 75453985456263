import {Component, Input} from '@angular/core';
import {VenteLabelPriceOptions} from '@features/dossier-bien/pricings/pricings.interfaces';
import Vente from '@models/vente/vente.model';
import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';

@Component({
    selector: 'app-vente-label-price',
    templateUrl: 'vente.label-price.component.html',
    styleUrls: ['vente.label-price.component.scss']
})
export class AppVenteLabelPriceComponent {
    readonly TYPE_CHARGES_HNI = VentePricingAbstract.TYPE_CHARGES_HNI;
    readonly TYPE_CHARGES_NV = VentePricingAbstract.TYPE_CHARGES_NV;

    private _options!: VenteLabelPriceOptions;
    private _vente!: Vente;

    @Input()
    set options(value: VenteLabelPriceOptions) {
        if (typeof value !== 'object') {
            value = {} as VenteLabelPriceOptions;
        }

        value.showNV = value.showNV !== false;

        this._options = value;
    }

    get options(): VenteLabelPriceOptions {
        return this._options;
    }

    @Input()
    set vente(value: Vente) {
        this._vente = value;
    }

    get vente(): Vente {
        return this._vente;
    }
}
