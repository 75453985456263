import {Component, Input} from '@angular/core';

@Component({template: ''})
export class AAppDiagGrapheV2ConsommationComponent {
    private _consommation!: number;
    private _emissions!: number;

    get consommation(): number {
        return this._consommation;
    }

    @Input()
    set consommation(value: number) {
        this._consommation = value;
    }

    get emissions(): number {
        return this._emissions;
    }

    @Input()
    set emissions(value: number) {
        this._emissions = value;
    }
}

@Component({templateUrl: 'consommation_A.svg'})
export class AppDiagGrapheV2ConsommationAComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_B.svg'})
export class AppDiagGrapheV2ConsommationBComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_C.svg'})
export class AppDiagGrapheV2ConsommationCComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_D.svg'})
export class AppDiagGrapheV2ConsommationDComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_E.svg'})
export class AppDiagGrapheV2ConsommationEComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_F.svg'})
export class AppDiagGrapheV2ConsommationFComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_G.svg'})
export class AppDiagGrapheV2ConsommationGComponent extends AAppDiagGrapheV2ConsommationComponent {
}

@Component({templateUrl: 'consommation_default.svg'})
export class AppDiagGrapheV2ConsommationStatutComponent extends AAppDiagGrapheV2ConsommationComponent {
}
