<div *ngIf="isDiagV1()">
  <h2>Diagnostics</h2>

  <div fxFlexFill fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="15px grid" class="no-print">
    <div fxFlex="40"><app-dpe [bien]="bien"></app-dpe></div>

    <div fxFlex="40"><app-ges [bien]="bien"></app-ges></div>
  </div>

  <div class="only-print">
    <div class="diag-container left-panel"><app-dpe [bien]="bien"></app-dpe></div>

    <div class="diag-container right-panel"><app-ges [bien]="bien"></app-ges></div>
  </div>
</div>

<div *ngIf="isDiagV2()">
  <app-diag-graphes-v2 [codeStatutDpe]="bien.performanceEnergetique.statutDpe"
                       [consommation]="bien.performanceEnergetique.consommation"
                       [consommationClasse]="bien.performanceEnergetique.consommationClasse"
                       [coutMax]="bien.performanceEnergetique.coutMax" [coutMin]="bien.performanceEnergetique.coutMin"
                       [dateReference]="bien.performanceEnergetique.dateReference"
                       [emissions]="bien.performanceEnergetique.emissions"
                       [emissionsClasse]="bien.performanceEnergetique.emissionsClasse" [options]="options">
  </app-diag-graphes-v2>
</div>
