<h1 mat-dialog-title>Demande d'étude de financement</h1>

<div mat-dialog-content>
  <mat-form-field *ngIf="!!cFinanceurs">
    <mat-label>Sélectionner un financeur</mat-label>

    <mat-select [(value)]="financeurSelected" (selectionChange)="onSelectFinanceur()">
      <mat-option *ngFor="let financeur of cFinanceurs.results" [value]="financeur">{{financeur.nom}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="!!financeurSelected">
    <div class="cgu" [innerHTML]="trustedCGU">
    </div>

    <mat-checkbox [(ngModel)]="checkConfirmation" color="primary">
      En cochant cette case, vous acceptez de transmettre vos données ci-dessous en vue d'être contacté par
      {{financeurSelected.nom}} pour une étude de financement.
    </mat-checkbox>

    <p class="personal-data">
      <span *ngIf="!!identity.convivialName">- {{identity.convivialName}}</span>

      <span *ngIf="!!identity.email">- {{identity.email}}</span>

      <span *ngIf="!!identity.mobile || !!identity.telephone">
        - {{!!identity.mobile.e164 ? identity.mobile.convivial : identity.telephone.convivial}}
      </span>
    </p>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="send()" [disabled]="!checkConfirmation" color="primary"
          [title]="!financeurSelected ? 'Sélectionner un financeur pour envoyer une demande' : (!checkConfirmation ? 'Cocher la confirmation pour envoyer une demande' : '')">
    Envoyer la demande
  </button>

  <button mat-button (click)="cancel()">Annuler</button>
</div>
