import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@core/routing/services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptor implements HttpInterceptor {
    private _authenticationService: AuthenticationService;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const xAuthToken = 'x-auth-token';

        if (this._authenticationService.currentClient?.token) {
            return next.handle(request.clone({setHeaders: {[xAuthToken]: this._authenticationService.currentClient.token}}));
        }

        return next.handle(request);
    }
}
