export default class Etude {
    private _crpcen!: string;
    private _etudeFinancement!: boolean;
    private _id!: number;
    private _nombreNotaires!: number;
    private _raisonSociale!: string;

    constructor(id: number) {
        this._id = id;
    }

    get crpcen(): string {
        return this._crpcen;
    }

    set crpcen(value: string) {
        this._crpcen = value;
    }

    get etudeFinancement(): boolean {
        return this._etudeFinancement;
    }

    set etudeFinancement(value: boolean) {
        this._etudeFinancement = value;
    }

    get id(): number {
        return this._id;
    }

    get nombreNotaires(): number {
        return this._nombreNotaires;
    }

    set nombreNotaires(value: number) {
        this._nombreNotaires = value;
    }

    get raisonSociale(): string {
        return this._raisonSociale;
    }

    set raisonSociale(value: string) {
        this._raisonSociale = value;
    }

    get urlMarianne(): string {
        if (this.nombreNotaires > 1) {
            return '/assets/marianne-notaires.svg';
        }

        return '/assets/marianne-notaire.svg';
    }
}
