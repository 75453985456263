<div class="container">
  <div [ngSwitch]="!!result">
    <div *ngSwitchCase="true">
      <div class="header-informations">
        <h1>{{result.dossierBien.titre}} - {{result.dossierBien.reference}}</h1><hr>
      </div>

      <div class="text-right no-print"><a routerLink="/results">Retour aux résultats</a></div>

      <div class="result-actions no-print">
        <a *ngIf="result.dossierBien.bien.photos.length > 0" mat-raised-button color="primary"
                (click)="showOriginalCarrousel(result.dossierBien.bien.photos)">
          <fa-icon icon="arrows-alt"></fa-icon>
        </a>

        <a *ngIf="result.dossierBien.bien.urlVisiteVirtuelle" mat-raised-button color="primary" target="_blank"
           [href]="result.dossierBien.bien.urlVisiteVirtuelleSanitize">
          <fa-icon icon="eye"></fa-icon>
        </a>

        <ng-container [ngSwitch]="!!currentClient.demandeur.responsableDossier.mobile">
          <a *ngSwitchCase="true" mat-raised-button color="primary"
             [href]="'tel: ' + currentClient.demandeur.responsableDossier.mobile.e164">
            <fa-icon icon="phone"></fa-icon>
          </a>

          <span *ngSwitchDefault>
            <a *ngIf="currentClient.demandeur.responsableDossier.telephone" mat-raised-button color="primary"
               [href]="'tel: ' + currentClient.demandeur.responsableDossier.telephone.e164">
              <fa-icon icon="phone"></fa-icon>
            </a>
          </span>
        </ng-container>

        <a mat-raised-button *ngIf="currentClient.demandeur.responsableDossier.email"
           title="{{currentClient.demandeur.responsableDossier.email}}" target="_blank"
           href="mailto:{{currentClient.demandeur.responsableDossier.email}}" color="primary">
          <fa-icon icon="envelope"></fa-icon>
        </a>

        <a *ngIf="this.enablePrint" mat-raised-button color="primary" (click)="print()">
          <fa-icon icon="print"></fa-icon>
        </a>
      </div>

      <app-dossier-details-container [dossierBien]="result.dossierBien" [photosIdx]="photosIdx"
                                     (getPhotosIdx)="getPhotosIdx($event)"></app-dossier-details-container>
    </div>

    <div *ngSwitchDefault class="spinner"><mat-spinner></mat-spinner></div>
  </div>
</div>
