import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorSentryService} from '@core/error/error.sentry.service';
import {User as UserSentry} from '@sentry/types';
import Client from '@core/models/client/client.model';

// @todo Voir comment supprimer cette interface
interface IResponsable {
    email: string;
    etude?: {crpcen: string; id: number; raisonSociale: string};
    nom: string;
    prenom: string;
    titre: string;
}

@Injectable()
export class ErrorConfigurationService {
    private _ignoredErrors: string[] = [];
    private _errorSentryService: ErrorSentryService;

    constructor(errorSentryService: ErrorSentryService) {
        this._errorSentryService = errorSentryService;
    }

    setUser(idDemandeur: number = null!, client: Client = null!): void {
        const userSentry: UserSentry = {};

        if (idDemandeur !== null) {
            userSentry.idDemandeur = idDemandeur;
        }

        if (client !== null) {
            userSentry.convivialName = client.identity.convivialName;
            userSentry.email = client.identity.email;
            if (client.demandeur && client.demandeur.responsableDossier) {
                const responsableDossier = client.demandeur.responsableDossier;
                const responsable: IResponsable = {
                    titre: responsableDossier.titre,
                    nom: responsableDossier.nom,
                    prenom: responsableDossier.prenom,
                    email: responsableDossier.email,
                };

                if (responsableDossier.site && responsableDossier.site.etude) {
                    responsable.etude = {
                        id: responsableDossier.site.etude.id,
                        crpcen: responsableDossier.site.etude.crpcen,
                        raisonSociale: responsableDossier.site.etude.raisonSociale
                    };
                }

                userSentry.responsable = JSON.stringify(responsable);
            }
        }

        this._errorSentryService.setUser(userSentry);
    }

    addIgnoredError(error: string): void {
        this._ignoredErrors.push(error);
    }

    isErrorIgnored(error: unknown): boolean {
        let stringError: string;

        if (error instanceof HttpErrorResponse) {
            stringError = error.message;
            if (500 <= error.status && error.status < 600) {
                return true;
            }

            if ([401].indexOf(error.status) >= 0) {
                return true;
            }
        } else if (error instanceof Error) {
            stringError = error.message;
        } else {
            try {
                stringError = (error as {toString(): string}).toString();
            } catch (e: unknown) {
                stringError = (e as {toString(): string}).toString();
            }
        }

        return this._ignoredErrors.some(ignoredError => stringError.trim().includes(ignoredError));
    }
}
