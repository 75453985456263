import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

@Pipe({name: 'appCurrency'})
export class AppCurrencyPipe implements PipeTransform {
    transform(value: number, currencyCode: string = 'EUR'): string {
        const fractionDigits = (value % 1 === 0 ? 0 : 2).toString();

        return formatCurrency(
            value,
            'fr-FR',
            getCurrencySymbol(currencyCode, 'wide'),
            currencyCode,
            '0.' + fractionDigits + '-' + fractionDigits,
        );
    }
}
