import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';
import {IVentePricingTypePrix} from '@models/vente/pricing/vente.pricing.interfaces';

// @todo Valider l'utilisation de tous les attributs
export default class VenteTraditionnellePricing extends VentePricingAbstract {
    private _prixHNI!: number;
    private _prixNV!: number;

    get pourcentageHonoraires(): number {
        return Math.round(this.honoraires / this.prixNV * 100 * 100) / 100;
    }

    get priceHNI(): number {
        return this.prixHNI;
    }

    get priceNV(): number {
        return this.prixNV;
    }

    get prixHNI(): number {
        return this._prixHNI;
    }

    set prixHNI(value: number) {
        this._prixHNI = value;
    }

    get prixNV(): number {
        return this._prixNV;
    }

    set prixNV(value: number) {
        this._prixNV = value;
    }

    get TYPE_PRICE(): IVentePricingTypePrix {
        // @todo Supprimer les "as"
        return {abreviation: 'Prix', code: 'prix', label: 'Prix de vente', no_price: 'Pas de prix de vente saisi'} as IVentePricingTypePrix;
    }
}
