import {Injectable} from '@angular/core';
import {IDemandeurApi} from '@core/models/demandeur/demandeur.interfaces';
import Demandeur from '@core/models/demandeur/demandeur.model';
import {UserFactory} from '@core/models/user/user.factory';
import {DemandeurApiService} from '@models/demandeur/demandeur.api.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DemandeurFactory {
    private _demandeurApiService: DemandeurApiService;
    private _userFactory: UserFactory;

    constructor(demandeurApiService: DemandeurApiService, userFactory: UserFactory) {
        this._demandeurApiService = demandeurApiService;
        this._userFactory = userFactory;
    }

    create(demandeurApi: IDemandeurApi): Demandeur {
        const demandeur = new Demandeur(demandeurApi.id);

        demandeur.responsableDossier = this._userFactory.create(demandeurApi.responsableDossier);

        return demandeur;
    }

    get$(id: number): Observable<Demandeur> {
        return this._demandeurApiService.get$(id).pipe(
            map(demandeurApi => {
                demandeurApi.id = id;

                return demandeurApi;
            }),
            map(demandeurApi => this.create(demandeurApi)),
        );
    }
}
