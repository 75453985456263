import {NgModule} from '@angular/core';
import {NavigationStart, Router, RouterModule, Routes} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RootComponent} from '@app/old_components/layouts/root/root.component';
import {ResultsListComponent} from '@app/old_components/results/results-list/results-list.component';
import {ResultsDetailComponent} from '@app/old_components/results/results-detail/results-detail.component';
import {UnsubscribeComponent} from '@app/old_components/unsubscribe/unsubscribe.component';
import {StartComponent} from '@app/old_components/layouts/start/start.component';
import {LoginComponent} from '@app/old_components/layouts/login/login.component';
import {AuthguardGuard} from '@core/routing/guards/authguard.guard';
import {AuthenticationInterceptor} from '@core/routing/services/authentication.interceptor';
import {RoutingService} from '@core/routing/services/routing.service';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import {filter, map} from 'rxjs/operators';
import Client from '@models/client/client.model';
import Demandeur from '@models/demandeur/demandeur.model';

const routes: Routes = [
    {
        path: 'results',
        component: RootComponent,
        canActivateChild: [AuthguardGuard],
        children: [{path: '', component: ResultsListComponent}, {path: ':id', component: ResultsDetailComponent}]
    },
    {
        path: 'unsubscribe',
        component: UnsubscribeComponent,
        canActivate: [AuthguardGuard]
    },
    {path: 'start', component: StartComponent},
    {path: 'login', component: LoginComponent},
    {path: '**', redirectTo: 'results'}
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)],
    providers: [
        AuthguardGuard,
        AuthenticationService,
        RoutingService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true}
    ]
})
export class AppRoutingModule {
    constructor(router: Router, authenticationService: AuthenticationService) {
        router.events.pipe(
            filter(event => event instanceof NavigationStart),
            map(event => event as NavigationStart),
            map(event => event.url.split('?')),
            filter(urlSplit => !urlSplit[1]),
            filter(_ => authenticationService.currentClient instanceof Client),
            filter(_ => authenticationService.currentClient.demandeur instanceof Demandeur),
        ).subscribe(urlSplit => {
            router.navigate([urlSplit[0]], {
                queryParams: {
                    token: authenticationService.currentClient.token,
                    idDemandeur: authenticationService.currentClient.demandeur.id.toString(),
                }
            });
        });
    }
}
