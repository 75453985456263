import {Injectable} from '@angular/core';
import Phone from '@core/models/phone/phone.model';

@Injectable({providedIn: 'root'})
export class PhoneFactory {
    create(phoneApi: string): Phone {
        const phone = new Phone();

        phone.e164 = phoneApi;

        return phone;
    }
}
