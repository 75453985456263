import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';
import DictionaryItem from '@models/dictionary/item/dictionary-item.model';
import {IVentePricingTypePrix} from '@models/vente/pricing/vente.pricing.interfaces';

// @todo Valider l'utilisation de tous les attributs
export default class VenteInteractivePricing extends VentePricingAbstract {
    // @todo Supprimer les "as"
    static readonly SYST_ENCHERES_ASC: DictionaryItem = {
        abreviation: 'Ascendantes',
        code: 'ascendantes',
        label: 'Enchères ascendantes'
    } as DictionaryItem;
    static readonly SYST_ENCHERES_DESC: DictionaryItem = {
        abreviation: 'Descendantes',
        code: 'descendantes',
        label: 'Enchères descendantes'
    } as DictionaryItem;

    // @todo Passer en string et utiliser un pipe dans la vue
    private _dateFin!: Date;
    private _pasOffre!: number;
    private _premiereOffre!: number;
    private _systemeEncheres!: DictionaryItem;

    get dateFin(): Date {
        return this._dateFin;
    }

    set dateFin(value: Date) {
        this._dateFin = value;
    }

    get pasOffre(): number {
        return this._pasOffre;
    }

    set pasOffre(value: number) {
        this._pasOffre = value;
    }

    get pourcentageHonoraires(): number {
        return null!;
    }

    get priceHNI(): number {
        return this.premiereOffre;
    }

    get priceNV(): number {
        return null!;
    }

    get premiereOffre(): number {
        return this._premiereOffre;
    }

    set premiereOffre(value: number) {
        this._premiereOffre = value;
    }

    get systemeEncheres(): DictionaryItem {
        return this._systemeEncheres;
    }

    set systemeEncheres(value: DictionaryItem) {
        this._systemeEncheres = value;
    }

    get TYPE_PRICE(): IVentePricingTypePrix {
        // @todo Supprimer les "as"
        return {abreviation: 'Première offre', code: 'premiereOffre', label: 'Première offre', no_price: 'Pas de première offre saisie'} as IVentePricingTypePrix;
    }
}
