import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import Result from '@app/old_class/result';
import {ResultsService} from '@app/old_services/results.service';
import {UserService} from '@app/old_services/user.service';
import {UtilsService} from '@app/old_services/utils.service';
import ResultsCollection from '@app/old_class/results-collection';

@Component({
    selector: 'app-results-list',
    templateUrl: 'results-list.component.html',
    styleUrls: ['results-list.component.scss']
})
export class ResultsListComponent implements OnInit {
    private _resultsService: ResultsService;
    private _router: Router;
    private _userService: UserService;
    private _utils: UtilsService;
    private _errorResult!: Error;
    private _resultsCollection!: ResultsCollection;
    private _urlMarianne!: string;

    constructor(resultsService: ResultsService, router: Router, userService: UserService, utils: UtilsService) {
        this._resultsService = resultsService;
        this._router = router;
        this._userService = userService;
        this._utils = utils;
    }

    get errorResult(): Error {
        return this._errorResult;
    }

    get resultsCollection(): ResultsCollection {
        return this._resultsCollection;
    }

    get urlMarianne(): string {
        return this._urlMarianne;
    }

    get utils(): UtilsService {
        return this._utils;
    }

    ngOnInit(): void {
        this._resultsService.getAll().subscribe(resultsCollection => this._resultsCollection = resultsCollection);
        this._urlMarianne = this._userService.getCurrentUser().site.etude.urlMarianne;
        this._errorResult = this._resultsService.errorResult;
        this._resultsService.errorResult = undefined!;
    }

    goToResult(result: Result): void {
        this._router.navigateByUrl('/results/' + result.id.toString());
    }
}
