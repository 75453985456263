import {NgModule} from '@angular/core';
import {AppDiagGraphesComponent} from '@shared/diagnostic-graphes/diagnostic-graphes.component';
import {AppDiagGraphesV2Component} from '@shared/diagnostic-graphes/v2/diagnostic-graphes-v2.component';
import {CommonModule} from '@angular/common';
import {
    AAppDiagGrapheV2ConsommationComponent,
    AppDiagGrapheV2ConsommationAComponent, AppDiagGrapheV2ConsommationBComponent, AppDiagGrapheV2ConsommationCComponent,
    AppDiagGrapheV2ConsommationDComponent, AppDiagGrapheV2ConsommationEComponent, AppDiagGrapheV2ConsommationFComponent,
    AppDiagGrapheV2ConsommationGComponent, AppDiagGrapheV2ConsommationStatutComponent
} from '@shared/diagnostic-graphes/v2/consommation/diagnostic-graphes-v2-consommation.component';
import {
    AAppDiagGrapheV2EmissionsComponent,
    AppDiagGrapheV2EmissionsAComponent, AppDiagGrapheV2EmissionsBComponent, AppDiagGrapheV2EmissionsCComponent,
    AppDiagGrapheV2EmissionsDComponent, AppDiagGrapheV2EmissionsEComponent, AppDiagGrapheV2EmissionsFComponent,
    AppDiagGrapheV2EmissionsGComponent, AppDiagGrapheV2EmissionsStatutComponent
} from '@shared/diagnostic-graphes/v2/emissions/diagnostic-graphes-v2-emissions.component';
import {AppDiagGrapheV2ConsommationComponent} from '@shared/diagnostic-graphes/v2/consommation/diagnostic-graphe-v2-consommation.component';
import {AppDiagGrapheV2EmissionsComponent} from '@shared/diagnostic-graphes/v2/emissions/diagnostic-graphe-v2-emissions.component';
import {CurrencyModule} from '@shared/currency/currency.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DpeComponent} from '@shared/diagnostic-graphes/v1/dpe/dpe.component';
import {GesComponent} from '@shared/diagnostic-graphes/v1/ges/ges.component';

@NgModule({
    declarations: [
        AAppDiagGrapheV2ConsommationComponent,
        AAppDiagGrapheV2EmissionsComponent,
        AppDiagGraphesComponent,
        AppDiagGraphesV2Component,
        AppDiagGrapheV2ConsommationAComponent,
        AppDiagGrapheV2ConsommationBComponent,
        AppDiagGrapheV2ConsommationCComponent,
        AppDiagGrapheV2ConsommationComponent,
        AppDiagGrapheV2ConsommationDComponent,
        AppDiagGrapheV2ConsommationEComponent,
        AppDiagGrapheV2ConsommationFComponent,
        AppDiagGrapheV2ConsommationGComponent,
        AppDiagGrapheV2ConsommationStatutComponent,
        AppDiagGrapheV2EmissionsAComponent,
        AppDiagGrapheV2EmissionsBComponent,
        AppDiagGrapheV2EmissionsCComponent,
        AppDiagGrapheV2EmissionsComponent,
        AppDiagGrapheV2EmissionsDComponent,
        AppDiagGrapheV2EmissionsEComponent,
        AppDiagGrapheV2EmissionsFComponent,
        AppDiagGrapheV2EmissionsGComponent,
        AppDiagGrapheV2EmissionsStatutComponent,
        DpeComponent,
        GesComponent,
    ],
    exports: [AppDiagGraphesComponent],
    imports: [CommonModule, CurrencyModule, FlexLayoutModule],
})
export class DiagnosticGraphesModule {
}
