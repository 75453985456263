import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {MaterialModule} from '@app/shared/material/material.module';
import {AppCurrencyPipe} from '@app/shared/currency/currency.pipe';
import {FAModule} from '@shared/fontawesome/fontawesome.module';
import {CurrencyModule} from '@shared/currency/currency.module';
import {DiagnosticGraphesModule} from '@shared/diagnostic-graphes/diagnostic-graphes.module';
import {AppDiagGraphesComponent} from '@shared/diagnostic-graphes/diagnostic-graphes.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
    exports: [AppCurrencyPipe, AppDiagGraphesComponent, FAModule, MaterialModule],
    imports: [CurrencyModule, DiagnosticGraphesModule, FAModule, MaterialModule],
    providers: [{provide: LOCALE_ID, useValue: 'fr-FR'}],
})
export class SharedModule {
}
