import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {RoutingService} from '@core/routing/services/routing.service';
import {AuthenticationService} from '@core/routing/services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthguardGuard implements CanActivate, CanActivateChild {
    private _routingService: RoutingService;
    private _authenticationService: AuthenticationService;

    constructor(routingService: RoutingService, authenticationService: AuthenticationService) {
        this._routingService = routingService;
        this._authenticationService = authenticationService;
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this._authenticationService.isConnected()) {
            this._routingService.goToStart(next, state);

            return false;
        }

        return true;
    }

    canActivateChild(next: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(next, state);
    }
}
