import {Component, Input} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {DictionaryService} from '@models/dictionary/dictionary.service';

@Component({selector: 'app-dpe', templateUrl: 'dpe.component.html'})
export class DpeComponent {
    private _dictionaryService: DictionaryService;
    private _bien!: Bien;
    private _statutLabel!: string;

    constructor(dictionaryService: DictionaryService) {
        this._dictionaryService = dictionaryService;
    }

    get bien(): Bien {
        return this._bien;
    }

    @Input()
    set bien(value: Bien) {
        this._bien = value;
        this._statutLabel = this._dictionaryService.getDiagnosticStatutByCode(this._bien.performanceEnergetique.statutDpe).label;
    }

    get statutLabel(): string {
        return this._statutLabel;
    }
}
