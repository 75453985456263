import {Injectable} from '@angular/core';
import {IBienApi} from '@core/models/bien/bien.interfaces';
import Bien from '@core/models/bien/bien.model';
import {CommuneFactory} from '@core/models/commune/commune.factory';
import {PieceFactory} from '@core/models/piece/piece.factory';
import {IPieceApi} from '@core/models/piece/piece.interfaces';
import {BienPerformanceEnergetiqueFactory} from '@models/bien/performance-energetique/bien-performance-energetique.factory';

@Injectable({providedIn: 'root'})
export class BienFactory {
    private _bienPerformanceEnergetiqueFactory: BienPerformanceEnergetiqueFactory;
    private _communeFactory: CommuneFactory;
    private _pieceFactory: PieceFactory;

    constructor(bienPerformanceEnergetiqueFactory: BienPerformanceEnergetiqueFactory,
                communeFactory: CommuneFactory,
                pieceFactory: PieceFactory) {
        this._bienPerformanceEnergetiqueFactory = bienPerformanceEnergetiqueFactory;
        this._communeFactory = communeFactory;
        this._pieceFactory = pieceFactory;
    }

    create(bienApi: IBienApi): Bien {
        const bien = new Bien();

        bien.accesPmr = bienApi.accesPmr;
        bien.anneeConstruction = bienApi.anneeConstruction;
        bien.etatLabel = bienApi.etatLabel;
        bien.exposition = bienApi.exposition;
        bien.habitable = bienApi.habitable;
        bien.huisseries = bienApi.huisseries;
        bien.materiauxCouverture = bienApi.materiauxCouverture;
        bien.natureCode = bienApi.natureCode;
        bien.nombreChambres = bienApi.nombreChambres;
        bien.nombrePieces = bienApi.nombrePieces;
        bien.nombreSalleDeBain = bienApi.nombreSalleDeBain;
        bien.performanceEnergetique = this._bienPerformanceEnergetiqueFactory.create(bienApi);
        bien.risquesZone = bienApi.risquesZone;
        bien.surfaceBatie = bienApi.surfaceBatie;
        bien.surfaceCadastrale = bienApi.surfaceCadastrale;
        bien.surfaceHabitable = bienApi.surfaceHabitable;
        bien.surfaceNonBatie = bienApi.surfaceNonBatie;
        bien.urlVisiteVirtuelle = bienApi.urlVisiteVirtuelle;

        if (Array.isArray(bienApi.photos)) {
            bienApi.photos.filter(photo => !!photo).forEach((photo: string) => bien.photos.push(photo));
        }

        if (Array.isArray(bienApi.pieces)) {
            bienApi.pieces.forEach((pieceApi: IPieceApi) => bien.pieces.push(this._pieceFactory.create(pieceApi)));
        }

        if (bienApi.ville instanceof Object) {
            bien.ville = this._communeFactory.create(bienApi.ville);
        }

        return bien;
    }
}
