<div [ngSwitch]="dossier.bien.photos.length > 0" class="no-print">
  <div class="carrousel" *ngSwitchCase="true">
    <app-images-carrousel [images]="dossier.bien.photos" [initCurrentIndex]="photosIdx"
                          (getCurrentIndex)="getCurrentIndex($event)"></app-images-carrousel>
  </div>

  <div *ngSwitchDefault class="default-img"><img mat-card-image [src]="urlMarianne"></div>
</div>

<div [ngSwitch]="dossier.bien.photos.length > 0" class="img-print">
  <div class="carrousel" *ngSwitchCase="true">
    <img id="photo-print" [src]="dossier.bien.photos[0] + '?size=original_rotated'"/>
  </div>

  <div *ngSwitchDefault class="default-img"><img id="marianne-print" mat-card-image [src]="urlMarianne"></div>
</div>
