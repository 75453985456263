<div *ngIf="vente.pricing.priceHNI > 0">
  <span title="{{vente.pricing.TYPE_PRICE.label + ' ' + (TYPE_CHARGES_HNI.label | lowercase)}}">
    {{vente.pricing.priceHNI | appCurrency}} <span class="type-charges">{{TYPE_CHARGES_HNI.abreviation}}</span>
  </span>

  <span *ngIf="options.showNV && vente.pricing.typeCharges === TYPE_CHARGES_NV" class="price-NV"
        title="{{vente.pricing.TYPE_PRICE.label + ' ' + (TYPE_CHARGES_NV.label | lowercase)}}">
    ({{vente.pricing.priceNV | appCurrency}} <span class="type-charges">{{TYPE_CHARGES_NV.abreviation}}</span>)
  </span>

  <span *ngIf="vente.pricing.baissePrix" class="price-down"
        title="Le {{vente.pricing.TYPE_PRICE.label | lowercase}} du bien a baissé le {{vente.pricing.baissePrixDate | date}}.">
    <fa-icon icon="arrow-right" transform="rotate-45"></fa-icon>
  </span>
</div>

<div *ngIf="vente.pricing.priceHNI <= 0">{{vente.pricing.TYPE_PRICE.no_price}}</div>
