import {Injectable} from '@angular/core';
import DictionaryItem from '@models/dictionary/item/dictionary-item.model';
import {DictionaryFactory} from '@models/dictionary/dictionary.factory';

@Injectable({providedIn: 'root'})
export class DictionaryService {
    private _dictionaryFactory: DictionaryFactory;

    constructor(dictionaryFactory: DictionaryFactory) {
        this._dictionaryFactory = dictionaryFactory;
    }

    getDiagnosticStatutByCode(code: string): DictionaryItem {
        let idx = this._dictionaryFactory.DiagnosticStatuts.findIndex(diagnosticStatut => diagnosticStatut.code === code);

        if (idx < 0) {
            idx = this._dictionaryFactory.DiagnosticStatuts.findIndex(diagnosticStatut => diagnosticStatut.code === DictionaryFactory.DiagnosticStatutNonCommunique.code);
        }

        return this._dictionaryFactory.DiagnosticStatuts[idx];
    }
}
