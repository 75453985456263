import {Injectable} from '@angular/core';
import environment from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UtilsService} from '@app/old_services/utils.service';

@Injectable({providedIn: 'root'})
export class ContactService {
    private _httpClient: HttpClient;
    private _utilsService: UtilsService;

    constructor(httpClient: HttpClient, utilsService: UtilsService) {
        this._httpClient = httpClient;
        this._utilsService = utilsService;
    }

    // @todo Une fois la classe Contact créée, y inclure cette méthode.
    unsubscribe(): Observable<any> {
        return this._httpClient.patch(`${environment.api.baseUrl}/espace-client/contact/unsubscribe`, undefined,  {headers: this._utilsService.getCommonHeaders()});
    }
}
