import {Injectable} from '@angular/core';
import {BienFactory} from '@models/bien/bien.factory';
import Vente from '@models/vente/vente.model';
import {IVenteApi} from '@models/vente/vente.interfaces';
import {VentePricingFactory} from '@models/vente/pricing/vente.pricing.factory';

@Injectable({providedIn: 'root'})
export class VenteFactory {
    private _bienFactory: BienFactory;
    private _ventePricingFactory: VentePricingFactory;

    constructor(bienFactory: BienFactory, ventePricingFactory: VentePricingFactory) {
        this._bienFactory = bienFactory;
        this._ventePricingFactory = ventePricingFactory;
    }

    create(venteApi: IVenteApi): Vente {
        const vente = new Vente(venteApi.uuid);

        vente.baissePrix = venteApi.baissePrix;
        vente.baissePrixDate = venteApi.baissePrixDate;
        vente.descriptif = venteApi.descriptif;
        vente.fraisActe = venteApi.fraisActe;
        vente.honoraires = venteApi.honoraires;
        vente.interactiveDateFin = venteApi.interactiveDateFin;
        vente.interactivePasOffre = venteApi.interactivePasOffre;
        vente.interactivePremiereOffre = venteApi.interactivePremiereOffre;
        vente.interactiveSystemeEncheres = venteApi.interactiveSystemeEncheres;
        vente.prix = venteApi.prix;
        vente.reference = venteApi.reference;
        vente.titre = venteApi.titre;
        vente.type = venteApi.type;
        vente.typeCharges = venteApi.typeCharges;

        if (venteApi.bien instanceof Object) {
            vente.bien = this._bienFactory.create(venteApi.bien);
        }

        if (venteApi.viager) {
            vente.viagerBouquet = venteApi.viager.bouquet;
            vente.viagerRente = venteApi.viager.rente;
            vente.viagerValeurBien = venteApi.viager.valeurBien;

            if (venteApi.viager.rentePeriodicite) {
                vente.viagerRentePeriodicite = venteApi.viager.rentePeriodicite.label;
            }
        }

        vente.pricing = this._ventePricingFactory.createFromVente(vente);

        return vente;
    }
}
