import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, timer} from 'rxjs';
import {map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: 'app-images-carrousel',
    templateUrl: 'images-carrousel.component.html',
    styleUrls: ['images-carrousel.component.scss']
})
export class ImagesCarrouselComponent implements OnInit, OnDestroy {
    @Output() getCurrentIndex = new EventEmitter<number>();
    private _currentIndex = 0;
    private _currentIndexSource = new Subject<number>();
    private _currentIndex$ = this._currentIndexSource.asObservable();
    private _height!: string;
    private _images!: string[];
    private _initCurrentIndex!: number;
    private readonly _onDestroy$ = new Subject<void>();
    private _paused!: boolean;
    private _width!: string;

    get currentIndex(): number {
        return this._currentIndex;
    }

    get height(): string {
        return this._height;
    }

    @Input()
    set height(value: string) {
        this._height = value;
    }

    get images(): string[] {
        return this._images;
    }

    @Input()
    set images(value: string[]) {
        this._images = value;
    }

    @Input()
    set initCurrentIndex(value: number) {
        this._initCurrentIndex = value;
    }

    get width(): string {
        return this._width;
    }

    @Input()
    set width(value: string) {
        this._width = value;
    }

    ngOnInit(): void {
        this._currentIndex$.pipe(
            startWith(this._initCurrentIndex),
            switchMap(currentIndex => timer(0, 3800).pipe(
                map(tick => {
                    const currentImg: HTMLImageElement = document.getElementById('img-' + this._currentIndex.toString()) as HTMLImageElement;

                    if (tick === 0 || (!this._paused && currentImg && currentImg.complete)) {
                        return currentIndex++;
                    }

                    return this._currentIndex;
                })
            )
            ),
            map(currentIndex => {
                if (currentIndex < 0) {
                    return this.images.length - 1;
                }

                return currentIndex % this.images.length;
            }),
            tap(currentIndex => this._currentIndex = currentIndex),
            takeUntil(this._onDestroy$),
        ).subscribe(currentIndex => this.getCurrentIndex.emit(currentIndex));
        // @todo Utiliser angular et pas le JS
        document.addEventListener('keyup', event => {
            const key = event.key;

            if (this.images.length <= 0) {
                return;
            } else if (key === 'ArrowRight') {
                this.next();
            } else if (key === 'ArrowLeft') {
                this.prev();
            }
        }, false);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    mouseEnter(): void {
        this._paused = true;
    }

    mouseLeave(): void {
        this._paused = false;
    }

    next(): void {
        this.setCurrentIndex(this._currentIndex + 1);
    }

    prev(): void {
        this.setCurrentIndex(this._currentIndex - 1);
    }

    setCurrentIndex(index: number): void {
        this._currentIndexSource.next(index);
    }

    showImg(index: number): boolean {
        if (index === this._currentIndex || (index + 1) === this._currentIndex || (index - 1) === this._currentIndex) {
            return true;
        }

        return ((this._currentIndex - 1) < 0 && index === (this.images.length - 1)) ||
            ((this._currentIndex + 1) >= this.images.length && index === 0);
    }
}
