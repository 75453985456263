import {Injectable} from '@angular/core';
import {IPieceApi} from '@core/models/piece/piece.interfaces';
import Piece from '@core/models/piece/piece.model';

@Injectable({providedIn: 'root'})
export class PieceFactory {
    create(pieceApi: IPieceApi): Piece {
        const piece = new Piece();

        piece.commentaire = pieceApi.commentaire;
        piece.etage = pieceApi.etage;
        piece.label = pieceApi.label ? pieceApi.label : pieceApi.otherLabel;
        piece.surface = pieceApi.surface;

        return piece;
    }
}
