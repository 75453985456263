import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import {ResultsService} from '@app/old_services/results.service';
import {UserService} from '@app/old_services/user.service';
import {UtilsService} from '@app/old_services/utils.service';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import Result from '@app/old_class/result';
import {DialogImagesComponent} from '@features/shared/dialogs/images/dialog-images.component';
import Client from '@core/models/client/client.model';

@Component({templateUrl: 'results-detail.component.html', styleUrls: ['results-detail.component.scss']})
export class ResultsDetailComponent implements OnInit {
    private _activatedRoute: ActivatedRoute;
    private _authenticationService: AuthenticationService;
    private _matDialog: MatDialog;
    private _resultsService: ResultsService;
    private _router: Router;
    private _userService: UserService;
    private _utils: UtilsService;
    private _currentClient!: Client;
    private _enablePrint!: boolean;
    private _photosIdx = 0;
    private _result!: Result;

    constructor(activatedRoute: ActivatedRoute,
                authenticationService: AuthenticationService,
                matDialog: MatDialog,
                resultsService: ResultsService,
                router: Router,
                userService: UserService,
                utilsService: UtilsService) {
        this._activatedRoute = activatedRoute;
        this._resultsService = resultsService;
        this._matDialog = matDialog;
        this._userService = userService;
        this._utils = utilsService;
        this._router = router;
        this._authenticationService = authenticationService;
    }

    get currentClient(): Client {
        return this._currentClient;
    }

    get enablePrint(): boolean {
        return this._enablePrint;
    }

    get photosIdx(): number {
        return this._photosIdx;
    }

    get result(): Result {
        return this._result;
    }

    ngOnInit(): void {
        const id = +this._activatedRoute.snapshot.paramMap.get('id')!;

        this._enablePrint = false;
        this._currentClient = this._authenticationService.currentClient;
        this._resultsService.getOneById(id).subscribe(result => {
            this._result = result;
        }, () => {
            this._router.navigateByUrl('results');
        }, () => {
            const idPhoto = this._result.dossierBien.bien.photos.length > 0 ? 'photo-print' : 'marianne-print';
            const interval = window.setInterval(() => {
                const currentImg: HTMLImageElement = document.getElementById(idPhoto) as HTMLImageElement;

                if (currentImg instanceof HTMLImageElement) {
                    window.clearInterval(interval);
                    this._enablePrint = true;
                }
            }, 500);
        });
    }

    getPhotosIdx(photosIdx: number): void {
        this._photosIdx = photosIdx;
    }

    showOriginalCarrousel(images: string[]): void {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {images, photosIdx: this._photosIdx};

        this._matDialog.open(DialogImagesComponent, dialogConfig).afterClosed().subscribe(data => console.log(data));
    }

    print(): void {
        window.print();
    }
}
