<section fxFlexFill
         fxLayout="row"
         fxLayout.sm="column"
         fxLayout.print="xs"
         fxLayout.xs="column">
  <div fxFlex="70" class="left-panel img-panel">
    <app-dossier-bien-photos [dossier]="dossierBien" [photosIdx]="photosIdx" (getPhotosIdx)="getPhotosIdxCB($event)">
    </app-dossier-bien-photos>
  </div>

  <div fxFlex="30" class="right-panel">
    <app-pricing-dossier [dossierBien]="dossierBien"></app-pricing-dossier>

    <table>
      <tbody>
      <tr>
        <td>Surface habitable</td>
        <td [ngSwitch]="!!dossierBien.bien.surfaceHabitable">
          <span *ngSwitchCase="true">{{dossierBien.bien.surfaceHabitable | number}}
            m<sup>2</sup></span>
          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>
      <tr>
        <td>Surface cadastrale</td>
        <td [ngSwitch]="!!dossierBien.bien.surfaceCadastrale">
          <span *ngSwitchCase="true">{{dossierBien.bien.surfaceCadastrale | number}}
            m<sup>2</sup></span>
          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>
      <tr>
        <td>Surface bâtie</td>
        <td [ngSwitch]="!!dossierBien.bien.surfaceBatie">
          <span *ngSwitchCase="true">{{dossierBien.bien.surfaceBatie | number}}
            m<sup>2</sup></span>
          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>
      <tr>
        <td>Surface non bâtie</td>
        <td [ngSwitch]="!!dossierBien.bien.surfaceNonBatie">
          <span *ngSwitchCase="true">{{dossierBien.bien.surfaceNonBatie | number}}
            m<sup>2</sup></span>
          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>

      <tr *ngIf="dossierBien.typeDossier === 'location'">
        <td>Disponibilité</td>
        <td [ngSwitch]="!!location.dateDisponibilite">
          <span *ngSwitchCase="true">{{location.dateDisponibilite | date}}</span>

          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>

      <tr *ngIf="dossierBien.typeDossier === 'location' && location.meuble === true">
        <td>Meublé</td>
        <td>OUI</td>
      </tr>
      </tbody>
    </table>
  </div>
</section>
<section>
  <p [innerHTML]="dossierBien.descriptif | newline" class="description"></p>
</section>

<section fxFlexFill fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
  <div fxFlex="70" class="left-panel">
    <div class="no-print"><app-diag-graphes [bien]="dossierBien.bien"></app-diag-graphes></div>
  </div>

  <div fxFlex="30" class="right-panel">
    <app-batiment-dossier [dossier]="dossierBien"></app-batiment-dossier>

    <p *ngIf="dossierBien.bien.risquesZone">
      Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site
      <a href="https://www.georisques.gouv.fr" target="_blank">
        Géorisques <fa-icon icon="external-link-alt"></fa-icon></a>.
    </p>
  </div>
</section>

<section class="only-print">
  <app-diag-graphes [bien]="dossierBien.bien"></app-diag-graphes>

  <p *ngIf="dossierBien.bien.risquesZone">
    Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site
    "<a>www.georisques.gouv.fr</a>".
  </p>
</section>
