import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgModule} from '@angular/core';
import {
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowRight,
    faArrowsAlt,
    faBath,
    faBed,
    faCircle,
    faCube,
    faEnvelope,
    faExternalLinkAlt,
    faEye,
    faMobileAlt,
    faPhone,
    faPrint
} from '@fortawesome/free-solid-svg-icons';

@NgModule({exports: [FontAwesomeModule], imports: [FontAwesomeModule]})
export class FAModule {
    constructor(iconLibrary: FaIconLibrary) {
        iconLibrary.addIcons(faArrowsAlt);
        iconLibrary.addIcons(faArrowCircleLeft);
        iconLibrary.addIcons(faArrowCircleRight);
        iconLibrary.addIcons(faArrowRight);
        iconLibrary.addIcons(faBath);
        iconLibrary.addIcons(faBed);
        iconLibrary.addIcons(faCircle);
        iconLibrary.addIcons(faCube);
        iconLibrary.addIcons(faEnvelope);
        iconLibrary.addIcons(faExternalLinkAlt);
        iconLibrary.addIcons(faEye);
        iconLibrary.addIcons(faMobileAlt);
        iconLibrary.addIcons(faPhone);
        iconLibrary.addIcons(faPrint);
    }
}
