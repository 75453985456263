import {Component, Input} from '@angular/core';
import {IDossierBien} from '@models/bien/bien.interfaces';

@Component({
    selector: 'app-pricing-dossier',
    templateUrl: 'pricing-dossier.component.html',
    styleUrls: ['pricing-dossier.component.scss']
})
export class PricingDossierComponent {
    private _dossierBien!: IDossierBien;

    @Input()
    set dossierBien(value: IDossierBien) {
        this._dossierBien = value;
    }

    get dossierBien(): IDossierBien {
        return this._dossierBien;
    }
}
