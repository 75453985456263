interface IApi {
    baseUrl: string;
}

interface ISentry {
    dsn: string;
    enabled: boolean;
    environment: string;
}

export default class Environment {
    private _api: IApi;
    private _production: boolean;
    private _sentry: ISentry;

    constructor(api: IApi, sentry: ISentry) {
        this._api = api;
        this._production = true;
        this._sentry = sentry;
    }

    get api(): IApi {
        return this._api;
    }

    get production(): boolean {
        return this._production;
    }

    set production(value: boolean) {
        this._production = value;
    }

    get sentry(): ISentry {
        return this._sentry;
    }

    set sentry(value: ISentry) {
        this._sentry = value;
    }
}
