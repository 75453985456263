import {Injectable} from '@angular/core';
import DictionaryItem from '@models/dictionary/item/dictionary-item.model';
import {IDictionaryItemApi} from '@models/dictionary/item/dictionary-item.interfaces';

@Injectable({providedIn: 'root'})
export class DictionaryItemFactory {
    create(dictionaryItemApi: IDictionaryItemApi): DictionaryItem {
        const dictionaryItem = new DictionaryItem();

        dictionaryItem.abreviation = dictionaryItemApi.abreviation;
        dictionaryItem.code = dictionaryItemApi.code;
        dictionaryItem.label = dictionaryItemApi.label;

        return dictionaryItem;
    }
}
