import {Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {
    AppDiagGrapheV2EmissionsAComponent, AppDiagGrapheV2EmissionsBComponent,
    AppDiagGrapheV2EmissionsCComponent, AppDiagGrapheV2EmissionsDComponent, AppDiagGrapheV2EmissionsEComponent,
    AppDiagGrapheV2EmissionsFComponent, AppDiagGrapheV2EmissionsGComponent, AppDiagGrapheV2EmissionsStatutComponent
} from '@shared/diagnostic-graphes/v2/emissions/diagnostic-graphes-v2-emissions.component';

// Ce component n'est pas testé car impossible de mocker ViewContainerRef.
// Penser à supprimer l'entrée dans "codeCoverageExclude" de "angular.json" si une solution est mise en place.
@Component({selector: 'app-diag-graphe-v2-emissions', template: ''})
export class AppDiagGrapheV2EmissionsComponent implements OnDestroy, OnInit {
    private _componentFactoryResolver: ComponentFactoryResolver;
    private _renderer2: Renderer2;
    private _viewContainerRef: ViewContainerRef;
    private _codeStatutDpe!: string;
    private _grapheEmissionsSource = new BehaviorSubject<void>(undefined);
    private _emissions!: number;
    private _emissionsClasse!: string;
    private _emissionsSource = new ReplaySubject<void>();
    private readonly _onDestroy$ = new Subject<void>();

    constructor(componentFactoryResolver: ComponentFactoryResolver,
                renderer2: Renderer2,
                viewContainerRef: ViewContainerRef) {
        this._componentFactoryResolver = componentFactoryResolver;
        this._renderer2 = renderer2;
        this._viewContainerRef = viewContainerRef;
    }

    @Input()
    set codeStatutDpe(value: string) {
        this._codeStatutDpe = value;
        this._grapheEmissionsSource.next();
    }

    @Input()
    set emissions(value: number) {
        this._emissions = value;
        this._emissionsSource.next();
    }

    @Input()
    set emissionsClasse(value: string) {
        this._emissionsClasse = value;
        this._grapheEmissionsSource.next();
    }

    ngOnInit(): void {
        const nativeElement = this._viewContainerRef.element.nativeElement as AppDiagGrapheV2EmissionsComponent;

        this._grapheEmissionsSource.asObservable().pipe(
            map(_ => {
                if (this._codeStatutDpe === 'disponible') {
                    if (this._emissionsClasse === 'A') {
                        return AppDiagGrapheV2EmissionsAComponent;
                    } else if (this._emissionsClasse === 'B') {
                        return AppDiagGrapheV2EmissionsBComponent;
                    } else if (this._emissionsClasse === 'C') {
                        return AppDiagGrapheV2EmissionsCComponent;
                    } else if (this._emissionsClasse === 'D') {
                        return AppDiagGrapheV2EmissionsDComponent;
                    } else if (this._emissionsClasse === 'E') {
                        return AppDiagGrapheV2EmissionsEComponent;
                    } else if (this._emissionsClasse === 'F') {
                        return AppDiagGrapheV2EmissionsFComponent;
                    } else {
                        return AppDiagGrapheV2EmissionsGComponent;
                    }
                } else {
                    return AppDiagGrapheV2EmissionsStatutComponent;
                }
            }),
            map(component => this._componentFactoryResolver.resolveComponentFactory(component)),
            tap(_ => this._viewContainerRef.clear()),
            map(componentFactory => this._viewContainerRef.createComponent(componentFactory)),
            tap(componentRef => this._renderer2.appendChild(nativeElement, componentRef.location.nativeElement)),
            switchMap(componentRef => this._emissionsSource.asObservable().pipe(map(_ => componentRef))),
            takeUntil(this._onDestroy$),
        ).subscribe(componentRef => componentRef.instance.emissions = this._emissions);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
