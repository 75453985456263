import {NgModule} from '@angular/core';
import {AppDossierBienLabelPriceComponent} from '@features/dossier-bien/pricings/label-price/dossier-bien.label-price.component';
import {AppVenteLabelPriceComponent} from '@features/dossier-bien/pricings/label-price/vente.label-price/vente.label-price.component';
import {SharedModule} from '@app/shared/shared.module';
import {CommonModule} from '@angular/common';
import {AppDossierBienPricingComponent} from '@features/dossier-bien/pricings/pricing/dossier-bien.pricing.component';
import {AppVentePricingComponent} from '@features/dossier-bien/pricings/pricing/vente.pricing/vente.pricing.component';
import {AppLocationLabelPriceComponent} from '@features/dossier-bien/pricings/label-price/location-label-price/location-label-price.component';
import {AppLocationPricingComponent} from '@features/dossier-bien/pricings/pricing/location-pricing/location-pricing.component';
import {ImagesCarrouselComponent} from '@features/shared/images-carrousel/images-carrousel.component';
import {DossierBienPhotosComponent} from '@features/dossier-bien/photos/dossier-bien-photos.component';
import {DialogImagesComponent} from '@features/shared/dialogs/images/dialog-images.component';

@NgModule({
    declarations: [
        AppDossierBienLabelPriceComponent,
        AppDossierBienPricingComponent,
        AppLocationLabelPriceComponent,
        AppLocationPricingComponent,
        AppVenteLabelPriceComponent,
        AppVentePricingComponent,
        DialogImagesComponent,
        DossierBienPhotosComponent,
        ImagesCarrouselComponent,
    ],
    exports: [
        AppDossierBienLabelPriceComponent,
        AppDossierBienPricingComponent,
        DialogImagesComponent,
        DossierBienPhotosComponent,
        ImagesCarrouselComponent,
    ],
    imports: [CommonModule, SharedModule],
})
export class FeaturesModule {
}
