import {Component, Input} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {BienPerformanceEnergetiqueService} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {IGraphesOptions} from '@shared/diagnostic-graphes/diagnostic-graphes.interfaces';

const graphesOptionsDefault: IGraphesOptions = {estimationCoutsVisibility: true, grapheEmissionsVisibility: true};

@Component({selector: 'app-diag-graphes', templateUrl: 'diagnostic-graphes.component.html'})
export class AppDiagGraphesComponent {
    private _bienPerformanceEnergetiqueService: BienPerformanceEnergetiqueService;
    private _bien!: Bien;
    private _options = graphesOptionsDefault;

    constructor(bienPerformanceEnergetiqueService: BienPerformanceEnergetiqueService) {
        this._bienPerformanceEnergetiqueService = bienPerformanceEnergetiqueService;
    }

    get bien(): Bien {
        return this._bien;
    }

    @Input()
    set bien(value: Bien) {
        this._bien = value;
    }

    get options(): IGraphesOptions {
        return this._options;
    }

    @Input()
    set options(value: IGraphesOptions) {
        this._options = {...graphesOptionsDefault, ...value};
    }

    isDiagV1(): boolean {
        return this._bienPerformanceEnergetiqueService.isDiagV1(this._bien);
    }

    isDiagV2(): boolean {
        return this._bienPerformanceEnergetiqueService.isDiagV2(this._bien);
    }
}
