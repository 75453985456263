<ng-container *ngIf="location.pricing.loyerEncadrement">
  <p>Zone soumise à encadrement des loyers</p>

  <p>Loyer de référence majoré : {{ location.pricing.loyerReferenceMajore | appCurrency }}
    / {{location.pricing.periodicite.abreviation}}</p>

  <p>Loyer de base : {{ location.pricing.loyerBase | appCurrency }}
    / {{location.pricing.periodicite.abreviation}}</p>

  <p *ngIf="location.pricing.loyerComplement">
    Complément de loyer : {{ location.pricing.loyerComplement | appCurrency }}
    / {{location.pricing.periodicite.abreviation}}
  </p>
</ng-container>

<p>
  Charges {{ location.pricing.chargesIncluses ? 'comprises' : 'en sus' }} :
  {{ location.pricing.charges | appCurrency }}
</p>

<p *ngIf="location.pricing.honoRedacChargeLocataire > 0">
  Honoraires de rédaction : {{ location.pricing.honoRedacChargeLocataire | appCurrency }}
</p>

<p *ngIf="location.pricing.etatLieuxChargeLocataire > 0">
  État des lieux : {{ location.pricing.etatLieuxChargeLocataire | appCurrency }}
</p>

<p *ngIf="location.pricing.depotGarantie > 0">
  Dépôt de garantie : {{ location.pricing.depotGarantie | appCurrency }}
</p>
