<h2>Bâtiment</h2>
<table>
  <tbody>
  <tr>
    <td>Année</td>
    <td>{{dossier.bien.anneeConstruction || 'N.C.'}}</td>
  </tr>
  <tr>
    <td>État</td>
    <td>{{dossier.bien.etatLabel || 'N.C.'}}</td>
  </tr>
  <tr>
    <td>Exposition</td>
    <td>{{dossier.bien.exposition || 'N.C.'}}</td>
  </tr>
  <tr *ngIf="dossier.bien.materiauxCouverture && dossier.bien.materiauxCouverture.length > 0">
    <td>Matériaux de toiture</td>
    <td>{{dossier.bien.materiauxCouverture.join(', ') || 'N.C.'}}</td>
  </tr>
  <tr *ngIf="dossier.bien.huisseries && dossier.bien.huisseries.length > 0">
    <td>Huisseries</td>
    <td>{{dossier.bien.huisseries.join(', ') || 'N.C.'}}</td>
  </tr>
  </tbody>
</table>
