import {Injectable} from '@angular/core';
import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import {IBienApi} from '@models/bien/bien.interfaces';

@Injectable({providedIn: 'root'})
export class BienPerformanceEnergetiqueFactory {
    create(bienApi: IBienApi): BienPerformanceEnergetique {
        const bienPerformanceEnergetique = new BienPerformanceEnergetique();

        bienPerformanceEnergetique.coutMax = bienApi.coutMaxDpe;
        bienPerformanceEnergetique.coutMin = bienApi.coutMinDpe;
        bienPerformanceEnergetique.date = bienApi.dateDpe;
        bienPerformanceEnergetique.statutDpe = bienApi.statutDpe;

        if (bienApi.anneeReferenceDpe) {
            bienPerformanceEnergetique.dateReference = (new Date(bienApi.anneeReferenceDpe)).toString();
        }

        if (bienApi.dpe instanceof Object) {
            bienPerformanceEnergetique.consommation = bienApi.dpe.valeur;
            bienPerformanceEnergetique.consommationClasse = bienApi.dpe.classe;
        }

        if (bienApi.ges instanceof Object) {
            bienPerformanceEnergetique.emissions = bienApi.ges.valeur;
            bienPerformanceEnergetique.emissionsClasse = bienApi.ges.classe;
        }

        return bienPerformanceEnergetique;
    }
}
