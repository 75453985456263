import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RoutingService} from '@core/routing/services/routing.service';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import {switchMap, take, tap} from 'rxjs/operators';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {DemandeurFactory} from '@models/demandeur/demandeur.factory';
import {HttpErrorResponse} from '@angular/common/http';

@Component({selector: 'app-start', templateUrl: 'start.component.html'})
export class StartComponent implements OnInit {
    private _activatedRoute: ActivatedRoute;
    private _authenticationService: AuthenticationService;
    private _demandeurFactory: DemandeurFactory;
    private _errorConfigurationService: ErrorConfigurationService;
    private _routingService: RoutingService;

    constructor(activatedRoute: ActivatedRoute,
                authenticationService: AuthenticationService,
                demandeurFactory: DemandeurFactory,
                errorConfigurationService: ErrorConfigurationService,
                routingService: RoutingService) {
        this._activatedRoute = activatedRoute;
        this._authenticationService = authenticationService;
        this._demandeurFactory = demandeurFactory;
        this._errorConfigurationService = errorConfigurationService;
        this._routingService = routingService;
    }

    ngOnInit(): void {
        const idDemandeur = this._activatedRoute.snapshot.queryParams.idDemandeur;

        if (!idDemandeur || idDemandeur <= 0) {
            this._routingService.goToLogin(new HttpErrorResponse({error: 'AuthenticationService.authenticate : No \'idDemandeur defined.'}));

            return;
        }

        this._authenticationService.authenticate(this._activatedRoute.snapshot.queryParams.token).pipe(
            tap(currentClient => this._errorConfigurationService.setUser(idDemandeur, currentClient)),
            switchMap(currentClient => this._demandeurFactory.get$(idDemandeur).pipe(
                tap(demandeur => currentClient.demandeur = demandeur),
                tap(_ => this._errorConfigurationService.setUser(currentClient.demandeur.id, currentClient)),
            )),
            take(1),
        ).subscribe({
            next: () => this._routingService.goToRedirection(),
            error: error => this._routingService.goToLogin(error)
        });
    }
}
