import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiResponseFormatterService} from '@core/api/api.response-formatter.service';
import environment from '@env/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class ApiService {
    private _apiResponseFormatterService: ApiResponseFormatterService;
    private _httpClient: HttpClient;

    constructor(
        apiResponseFormatterService: ApiResponseFormatterService,
        httpClient: HttpClient,
    ) {
        this._apiResponseFormatterService = apiResponseFormatterService;
        this._httpClient = httpClient;
    }

    delete$(path: string): Observable<void> {
        return this._httpClient.delete(environment.api.baseUrl + '/espace-client' + path).pipe(map(_ => undefined as void));
    }

    get$<T>(path: string, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.get(environment.api.baseUrl + '/espace-client' + path, {...{observe: 'body'}, ...httpOptions} as unknown).pipe(
            map(data => data as unknown as T),
        );
    }

    getResponse$<T>(path: string): Observable<T> {
        return this.get$<HttpResponse<T>>(path, {observe: 'response'}).pipe(
            map((httpResponse: HttpResponse<T>): T => this._apiResponseFormatterService.getBody<T>(httpResponse, {withLinks: true})),
        );
    }

    patch$<T>(path: string, body: unknown): Observable<T> {
        return this._httpClient.patch<T>(environment.api.baseUrl + '/espace-client' + path, body).pipe(
            map(data => data as unknown as T)
        );
    }

    post$<T>(path: string, body: T, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.post<T>(environment.api.baseUrl + '/espace-client' + path, body, httpOptions as unknown).pipe(
            map(data => data as unknown as T)
        );
    }

    put$<T>(path: string, body: T, httpOptions: HttpOptions = {}): Observable<T> {
        return this._httpClient.put<T>(environment.api.baseUrl + '/espace-client' + path, body, httpOptions as unknown);
    }
}
