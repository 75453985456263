// @todo Renommer en BienPIece et ranger dans Bien
// @todo Valider l'utilisation de tous les attributs
export default class Piece {
    private _commentaire!: string;
    private _etage!: string;
    private _label!: string;
    private _surface!: number;

    get commentaire(): string {
        return this._commentaire;
    }

    set commentaire(value: string) {
        this._commentaire = value;
    }

    get etage(): string {
        return this._etage;
    }

    set etage(value: string) {
        this._etage = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get surface(): number {
        return this._surface;
    }

    set surface(value: number) {
        this._surface = value;
    }
}
