import Location from '@models/location/location.model';
import {IDossierBien} from '@models/bien/bien.interfaces';
import Vente from '@models/vente/vente.model';

export default class Result {
    private _dossierBien!: IDossierBien;
    private _id!: number;
    private _location!: Location;
    private _vente!: Vente;

    get dossierBien(): IDossierBien {
        return this._dossierBien;
    }

    set dossierBien(value: IDossierBien) {
        this._dossierBien = value;
    }

    get id(): number {
        return this._id;
    }

    // @todo Supprimer la saisie d'un id hors constructor
    set id(value: number) {
        this._id = value;
    }

    get location(): Location {
        return this._location;
    }

    set location(value: Location) {
        this._location = value;
    }

    get vente(): Vente {
        return this._vente;
    }

    set vente(value: Vente) {
        this._vente = value;
    }
}
