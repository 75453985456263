import {Injectable} from '@angular/core';
import {IEtudeApi} from '@core/models/etude/etude.interfaces';
import Etude from '@core/models/etude/etude.model';

@Injectable({providedIn: 'root'})
export class EtudeFactory {
    create(etudeApi: IEtudeApi): Etude {
        const etude = new Etude(etudeApi.id);

        etude.crpcen = etudeApi.crpcen;
        etude.etudeFinancement = etudeApi.etudeFinancement;
        etude.nombreNotaires = etudeApi.nombreNotaires;
        etude.raisonSociale = etudeApi.raisonSociale;

        return etude;
    }
}
