import Result from '@app/old_class/result';

export default class ResultsCollection {
    private _collection: Result[] = [];
    private _total = 0;

    get total(): number {
        return this._total;
    }

    set total(total: number) {
        this._total = total;
    }

    get collection(): Result[] {
        return this._collection;
    }

    set collection(collection: Result[]) {
        this._collection = collection;
    }
}
