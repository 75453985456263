import {Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {ICFinanceursApi} from '@models/financeurs/collection/financeurs.collection.interfaces';
import {IFinanceurDemandeApi} from '@models/financeurs/financeur/financeur.interfaces';

@Injectable({providedIn: 'root'})
export class FinanceursApiService {
    private _modelApiService: ModelApiService;

    constructor(modelApiService: ModelApiService) {
        this._modelApiService = modelApiService;
    }

    addDemande$(uuid: string, financeurDemandeApi: IFinanceurDemandeApi): Observable<IFinanceurDemandeApi> {
        return this._modelApiService.post$<IFinanceurDemandeApi>(`/financeurs/${uuid}/demandes`, financeurDemandeApi);
    }

    getCollection$(): Observable<ICFinanceursApi> {
        return this._modelApiService.getCollection$<ICFinanceursApi>('/financeurs');
    }
}
