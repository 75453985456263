import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ApiInterceptor} from '@core/api/api-interceptor';

@NgModule({
    imports: [HttpClientModule],
    providers: [{provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true}],
})
export class ApiModule {
}
