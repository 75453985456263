import Phone from '@core/models/phone/phone.model';
import {IIdentity} from '@core/models/model.interfaces';

export default class Person implements IIdentity {
    private _email!: string;
    private _mobile!: Phone;
    private _nom!: string;
    private _prenom!: string;
    private _telephone!: Phone;
    private _titre!: string;

    get convivialName(): string {
        return (!!this.titre ? (this.titre + ' ') : '') + this.prenom + ' ' + this.nom;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get mobile(): Phone {
        return this._mobile;
    }

    set mobile(value: Phone) {
        this._mobile = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
    }

    get prenom(): string {
        return this._prenom;
    }

    set prenom(value: string) {
        this._prenom = value;
    }

    get telephone(): Phone {
        return this._telephone;
    }

    set telephone(value: Phone) {
        this._telephone = value;
    }

    get titre(): string {
        return this._titre;
    }

    set titre(titre: string) {
        switch (titre) {
            case 'm' :
                this._titre = 'M.';
                break;
            case 'mme' :
                this._titre = 'Mme';
                break;
            case 'mlle' :
                this._titre = 'Mlle';
                break;
            case 'vve' :
                this._titre = 'Vve';
                break;
            case 'me' :
                this._titre = 'Me';
                break;
            case 'dr' :
                this._titre = 'Dr';
                break;
            case 'pr' :
                this._titre = 'Pr';
                break;
            default:
                this._titre = titre;
        }
    }
}
