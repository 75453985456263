import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from '@app/old_services/user.service';
import {RoutingService} from '@core/routing/services/routing.service';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
    private _routingService: RoutingService;
    private _userService: UserService;
    private _errorLogin!: HttpErrorResponse;
    private _urlMarianne!: string;

    constructor(routingService: RoutingService, userService: UserService) {
        this._routingService = routingService;
        this._userService = userService;
    }

    get errorLogin(): HttpErrorResponse {
        return this._errorLogin;
    }

    get urlMarianne(): string {
        return this._urlMarianne;
    }

    ngOnInit(): void {
        this._urlMarianne = this._userService.getCurrentUser() ? this._userService.getCurrentUser().site.etude.urlMarianne : '/assets/marianne-notaire.svg';
        this._errorLogin = this._routingService.errorLogin;
    }
}
