import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DemandeEtudeFinancementComponent} from '@app/old_components/dialogs/demande-etude-financement/demande-etude-financement.component';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '@core/core.module';
import {SharedModule} from '@app/shared/shared.module';
import {AppRoutingModule} from '@core/routing/app-routing.module';
import {FeaturesModule} from '@app/features/features.module';
import {AppComponent} from '@app/app.component';
import {ContactService} from '@app/old_services/contact.service';
import {UserService} from '@app/old_services/user.service';
import {ResultsService} from '@app/old_services/results.service';
import {BatimentDossierComponent} from '@app/old_components/dossier-details/partials/batiment-dossier/batiment-dossier.component';
import {DossierDetailsContainerComponent} from '@app/old_components/dossier-details/dossier-details-container.component';
import {FooterComponent} from '@app/old_components/layouts/footer/footer.component';
import {LoginComponent} from '@app/old_components/layouts/login/login.component';
import {PieceDossierComponent} from '@app/old_components/dossier-details/partials/piece-dossier/piece-dossier.component';
import {PricingDossierComponent} from '@app/old_components/dossier-details/partials/pricing-dossier/pricing-dossier.component';
import {RootComponent} from '@app/old_components/layouts/root/root.component';
import {ResultsDetailComponent} from '@app/old_components/results/results-detail/results-detail.component';
import {ResultsListComponent} from '@app/old_components/results/results-list/results-list.component';
import {StartComponent} from '@app/old_components/layouts/start/start.component';
import {UnsubscribeComponent} from '@app/old_components/unsubscribe/unsubscribe.component';
import {AppartementDetailsComponent} from '@app/old_components/dossier-details/appartement-details/appartement-details.component';
import {AutreDetailsComponent} from '@app/old_components/dossier-details/autre-details/autre-details.component';
import {AutreTerrainDetailsComponent} from '@app/old_components/dossier-details/autre-terrain-details/autre-terrain-details.component';
import {BureauxDetailsComponent} from '@app/old_components/dossier-details/bureaux-details/bureaux-details.component';
import {GarageDetailsComponent} from '@app/old_components/dossier-details/garage-details/garage-details.component';
import {ImmeubleDetailsComponent} from '@app/old_components/dossier-details/immeuble-details/immeuble-details.component';
import {LocauxCommerciauxDetailsComponent} from '@app/old_components/dossier-details/locaux-commerciaux-details/locaux-commerciaux-details.component';
import {MaisonDetailsComponent} from '@app/old_components/dossier-details/maison-details/maison-details.component';
import {TerrainABatirDetailsComponent} from '@app/old_components/dossier-details/terrain-a-batir-details/terrain-a-batir-details.component';
import {NewlinePipe} from '@app/old_components/tools/pipes/newline';
import {ResultsCollectionFactory} from '@app/old_factories/results-collection.factory';
import {UtilsService} from '@app/old_services/utils.service';

@NgModule({
    declarations: [
        AppComponent,
        BatimentDossierComponent,
        DemandeEtudeFinancementComponent,
        DossierDetailsContainerComponent,
        FooterComponent,
        LoginComponent,
        PieceDossierComponent,
        PricingDossierComponent,
        NewlinePipe,
        ResultsDetailComponent,
        ResultsListComponent,
        RootComponent,
        StartComponent,
        UnsubscribeComponent,
        AppartementDetailsComponent,
        AutreDetailsComponent,
        AutreTerrainDetailsComponent,
        BureauxDetailsComponent,
        GarageDetailsComponent,
        ImmeubleDetailsComponent,
        LocauxCommerciauxDetailsComponent,
        MaisonDetailsComponent,
        TerrainABatirDetailsComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        FeaturesModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
    ],
    providers: [
        ResultsService,
        UserService,
        UtilsService,
        ContactService,
        ResultsCollectionFactory,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
