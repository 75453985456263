<footer>
  <div class="container">
    <mat-toolbar *ngIf="user" color="secondary" fxFlexFill fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
      <div class="marianne"><img [src]="user.site.etude.urlMarianne"></div>

      <div class="description">
        <h2>{{user.site.etude.raisonSociale}}</h2>

        <h3>{{user.convivialName}}</h3>

        <p>
          {{user.site.adresse}} {{user.site.codePostal}} {{user.site.commune.nom}}<br>

          <span *ngIf="user.mobile.e164">
            <fa-icon icon="mobile-alt"></fa-icon>

            <a [href]="'tel: ' + user.mobile.e164">{{user.mobile.convivial}}</a>
            -
          </span>

          <span *ngIf="user.telephone.e164">
            <fa-icon icon="phone"></fa-icon>

            <a [href]="'tel: ' + user.telephone.e164">{{user.telephone.convivial}}</a>
            -
          </span>

          <fa-icon icon="envelope"></fa-icon>

          <a href="mailto:{{user.email}}">{{user.email}}</a>
        </p>
      </div>
    </mat-toolbar>
  </div>
</footer>
