import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import ABOUT from '@env/about';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    intercept<T = any>(httpRequest: HttpRequest<T>, httpHandler: HttpHandler): Observable<HttpEvent<T>> {
        const acceptContentType = 'application/vnd.noty.api+json;version=' + ABOUT.versionAPI;
        const contentType = 'Content-Type';

        return httpHandler.handle(httpRequest.clone({
            setHeaders: {Accept: acceptContentType, [contentType]: acceptContentType},
        }));
    }
}
