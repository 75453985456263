import Bien from '@models/bien/bien.model';
import {IDossierBien} from '@models/bien/bien.interfaces';

// @todo AOldDossier => AOldDossierBien ? et voir également pour l'interface qui suit
export default abstract class AOldDossier implements IDossierBien {
    private _bien!: Bien;
    private _descriptif!: string;
    private _reference!: string;
    private _titre!: string;
    private _uuid: string;

    constructor(uuid: string) {
        this._uuid = uuid;
    }

    get bien(): Bien {
        return this._bien;
    }

    set bien(value: Bien) {
        this._bien = value;
    }

    get descriptif(): string {
        return this._descriptif;
    }

    set descriptif(descriptif: string) {
        this._descriptif = descriptif;
    }

    get reference(): string {
        return this._reference;
    }

    set reference(reference: string) {
        this._reference = reference;
    }

    get titre(): string {
        return this._titre;
    }

    set titre(value: string) {
        this._titre = value;
    }

    get uuid(): string {
        return this._uuid;
    }

    abstract get typeDossier(): string;
}
