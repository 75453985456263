import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ApiResponseFormatterService {
    getBody<T>(httpResponse: HttpResponse<T>, options?: {withLinks?: boolean}): T {
        const optionsBody: {links?: string} = {};

        if (options?.withLinks) {
            optionsBody.links = httpResponse.headers.get('link');
        }

        return {...httpResponse.body, ...optionsBody};
    }
}
