import {Injectable} from '@angular/core';
import {ResultFactory} from '@models/result/result.factory';
import {FactoryInterface} from '@app/old_factories/factory.interface';
import ResultsCollection from '@app/old_class/results-collection';

@Injectable({providedIn: 'root'})
export class ResultsCollectionFactory implements FactoryInterface {
    private _resultFactory: ResultFactory;

    constructor(resultFactory: ResultFactory) {
        this._resultFactory = resultFactory;
    }

    createFromJson(data: any): ResultsCollection {
        const resultsCollection = new ResultsCollection();

        resultsCollection.collection = [];
        data['_embedded'].items.map((result: any) => {
            resultsCollection.collection.push(this._resultFactory.create(result));
        });
        resultsCollection.total = data['total'];

        return resultsCollection;
    }
}
