import {Injectable} from '@angular/core';
import {ISiteApi} from '@core/models/site/site.interfaces';
import Site from '@core/models/site/site.model';
import {CommuneFactory} from '@core/models/commune/commune.factory';
import {EtudeFactory} from '@core/models/etude/etude.factory';

@Injectable({providedIn: 'root'})
export class SiteFactory {
    private _communeFactory: CommuneFactory;
    private _etudeFactory: EtudeFactory;

    constructor(communeFactory: CommuneFactory, etudeFactory: EtudeFactory) {
        this._communeFactory = communeFactory;
        this._etudeFactory = etudeFactory;
    }

    create(siteApi: ISiteApi): Site {
        const site = new Site();

        site.adresse = siteApi.adresse;
        site.codePostal = siteApi.codePostal;
        site.commune = this._communeFactory.create(siteApi.commune);
        site.etude = this._etudeFactory.create(siteApi.etude);

        return site;
    }
}
