import {Component, Input} from '@angular/core';
import Location from '@models/location/location.model';

@Component({
    selector: 'app-location-pricing',
    templateUrl: 'location-pricing.component.html',
    styleUrls: ['location-pricing.component.scss']
})
export class AppLocationPricingComponent {
    private _location!: Location;

    @Input()
    set location(value: Location) {
        this._location = value;
    }

    get location(): Location {
        return this._location;
    }
}
