import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import ABOUT from '@env/about';

@Injectable({providedIn: 'root'})
export class UtilsService {
    // ["MAISON", "APPARTEMENT", "IMMEUBLE", "BUREAUX", "LOCAUX_COMMERCIAUX", "GARAGE", "TERRAIN_A_BATIR", "AUTRE_TERRAIN", "AUTRE"]
    getlabelSuperficie(nature: string): string {
        switch (nature) {
            case 'MAISON':
            case 'APPARTEMENT':
            case 'IMMEUBLE':
                return 'Surface habitable';
            case 'BUREAUX':
            case 'LOCAUX_COMMERCIAUX':
                return 'Surface utile';
            case 'TERRAIN_A_BATIR':
            case 'AUTRE_TERRAIN':
                return 'Surface terrain';
            default:
                return 'Surface';
        }
    }

    showFieldForNature(field: string, nature: string): boolean {
        const restrictions: { [key: string]: string[] } = {
            nombrePieces: ['IMMEUBLE', 'BUREAUX', 'LOCAUX_COMMERCIAUX', 'GARAGE', 'TERRAIN_A_BATIR', 'AUTRE_TERRAIN'],
            nombreChambres: ['IMMEUBLE', 'BUREAUX', 'LOCAUX_COMMERCIAUX', 'GARAGE', 'TERRAIN_A_BATIR', 'AUTRE_TERRAIN'],
            surfaceBatie: ['TERRAIN_A_BATIR', 'AUTRE_TERRAIN', 'GARAGE'],
            surfaceNonBatie: ['TERRAIN_A_BATIR', 'AUTRE_TERRAIN', 'GARAGE'],
            anneeConstruction: ['TERRAIN_A_BATIR', 'AUTRE_TERRAIN'],
        };

        if (!nature || !field || !restrictions[field]) {
            return true;
        }

        return restrictions[field].indexOf(nature) === -1;
    }

    showSectionForNature(section: string, nature: string): boolean {
        const restrictions: { [key: string]: string[] } = {
            batiment: ['TERRAIN_A_BATIR', 'AUTRE_TERRAIN'],
            bienInfo: ['IMMEUBLE', 'BUREAUX', 'LOCAUX_COMMERCIAUX', 'GARAGE', 'TERRAIN_A_BATIR', 'AUTRE_TERRAIN'],
        };

        if (!nature || !section || !restrictions[section]) {
            return true;
        }

        return restrictions[section].indexOf(nature) === -1;
    }

    // @todo À supprimer car ApiInterceptor.intercept() fait le travail
    getCommonHeaders(additionalHeaders: any = {}): HttpHeaders {
        return new HttpHeaders({...{Accept: 'application/vnd.noty.api+json;version=' + ABOUT.versionAPI}, ...additionalHeaders});
    }
}
