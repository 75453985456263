import {Component} from '@angular/core';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import Client from '@core/models/client/client.model';

@Component({
    selector: 'app-root',
    templateUrl: 'root.component.html',
    styleUrls: ['root.component.scss']
})
export class RootComponent {
    private _authenticationService: AuthenticationService;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    get currentClient(): Client {
        return this._authenticationService.currentClient;
    }
}
