import {Injectable} from '@angular/core';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import User from '@core/models/user/user.model';

@Injectable({providedIn: 'root'})
export class UserService {
    private _authenticationService: AuthenticationService;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    // @todo Faire un current$
    getCurrentUser(): User {
        if (!this._authenticationService.isConnected()) {
            return undefined!;
        }

        return this._authenticationService.currentClient.demandeur.responsableDossier;
    }
}
