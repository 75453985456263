import User from '@core/models/user/user.model';

export default class Demandeur {
    private _id: number;
    private _responsableDossier!: User;

    constructor(id: number) {
        this._id = id;
    }

    get id(): number {
        return this._id;
    }

    get responsableDossier(): User {
        return this._responsableDossier;
    }

    set responsableDossier(responsableDossier: User) {
        this._responsableDossier = responsableDossier;
    }
}
