import {Component, Input} from '@angular/core';
import User from '@core/models/user/user.model';

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss']
})
export class FooterComponent {
    private _user!: User;

    get user(): User {
        return this._user;
    }

    @Input()
    set user(value: User) {
        this._user = value;
    }
}
