import {IVentePricingTypeCharges, IVentePricingTypePrix} from '@models/vente/pricing/vente.pricing.interfaces';

export default abstract class VentePricingAbstract {
    static readonly TYPE_CHARGES_HNI: IVentePricingTypeCharges = {
        abreviation: 'HNI',
        aCharge: 'Charge vendeur',
        code: 'honoraires_nego_inclus',
        label: 'Honoraires de négociation inclus'
    };
    static readonly TYPE_CHARGES_NV: IVentePricingTypeCharges = {
        abreviation: 'NV',
        aCharge: 'Charge acquéreur',
        code: 'net_vendeur',
        label: 'Net vendeur'
    };

    private _baissePrix!: boolean;
    private _baissePrixDate!: string;
    private _fraisActe!: number;
    private _honoraires!: number;
    private _typeCharges!: IVentePricingTypeCharges;

    abstract get pourcentageHonoraires(): number;
    abstract get priceHNI(): number;
    abstract get priceNV(): number;
    abstract get TYPE_PRICE(): IVentePricingTypePrix;

    get baissePrix(): boolean {
        return this._baissePrix;
    }

    set baissePrix(value: boolean) {
        this._baissePrix = value;
    }

    get baissePrixDate(): string {
        return this._baissePrixDate;
    }

    set baissePrixDate(value: string) {
        this._baissePrixDate = value;
    }

    get fraisActe(): number {
        return this._fraisActe;
    }

    set fraisActe(value: number) {
        this._fraisActe = value;
    }

    get honoraires(): number {
        return this._honoraires;
    }

    set honoraires(value: number) {
        this._honoraires = value;
    }

    get typeCharges(): IVentePricingTypeCharges {
        return this._typeCharges;
    }

    set typeCharges(value: IVentePricingTypeCharges) {
        this._typeCharges = value;
    }
}
