import Demandeur from '@core/models/demandeur/demandeur.model';
import {IIdentity} from '@core/models/model.interfaces';

export default class Client {
    private _demandeur: Demandeur;
    private _identity: IIdentity;
    private _token: string;

    get demandeur(): Demandeur {
        return this._demandeur;
    }

    set demandeur(value: Demandeur) {
        this._demandeur = value;
    }

    get identity(): IIdentity {
        return this._identity;
    }

    set identity(value: IIdentity) {
        this._identity = value;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }
}
