import {Injectable} from '@angular/core';
import {ApiService} from '@core/api/api.service';
import {Observable} from 'rxjs';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class ModelApiService {
    private _apiService: ApiService;

    constructor(apiService: ApiService) {
        this._apiService = apiService;
    }

    delete$(path: string): Observable<void> {
        return this._apiService.delete$(path);
    }

    get$<T>(path: string, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.get$<T>(path, httpOptions);
    }

    getCollection$<T>(path: string): Observable<T> {
        return this._apiService.getResponse$<T>(path);
    }

    patch$<B, T>(path: string, body?: B): Observable<T> {
        return this._apiService.patch$<T>(path, body);
    }

    post$<T>(path: string, body?: T, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.post$<T>(path, body!, httpOptions);
    }

    put$<T>(path: string, body: T, httpOptions?: HttpOptions): Observable<T> {
        return this._apiService.put$<T>(path, body, httpOptions);
    }

    stringifyParams(params: unknown, prefix?: string): string {
        const serializedParams: string[] = [];
        const paramsIsArray = Array.isArray(params);

        for (const [key, value] of Object.entries(params)) {
            if (value) {
                const thisPrefix = prefix ? prefix + '[' + (paramsIsArray ? '' : key) + ']' : key;

                serializedParams.push(typeof value === 'object'
                    ? this.stringifyParams(value as { [key: string]: unknown }, thisPrefix)
                    : thisPrefix + '=' + encodeURIComponent(value as boolean | number | string));
            }
        }

        return serializedParams.join('&');
    }
}
