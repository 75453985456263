import Links from '@models/links/links.model';

export default class Collection<T = unknown> {
    private _links: Links;
    private _page = 0;
    private _pages = 0;
    private _perPage = 0;
    private _results: T[] = [];
    private _total = 0;

    get links(): Links {
        return this._links;
    }

    set links(value: Links) {
        this._links = value;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get pages(): number {
        return this._pages;
    }

    set pages(value: number) {
        this._pages = value;
    }

    get perPage(): number {
        return this._perPage;
    }

    set perPage(value: number) {
        this._perPage = value;
    }

    get results(): T[] {
        return this._results;
    }

    set results(value: T[]) {
        this._results = value;
    }

    get total(): number {
        return this._total;
    }

    set total(value: number) {
        this._total = value;
    }

    addResult(result: T): void {
        this.results.push(result);
        this.total++;
    }

    removeResult(filterResults: (result: T) => boolean): void {
        let idx = -1;

        this.results.filter((result, index) => {
            if (typeof filterResults === 'function' && filterResults(result)) {
                idx = index;
            }
        });

        if (idx < 0 || this.total < idx) {
            return;
        }

        this.results.splice(idx, 1);
        this.total--;
    }
}
