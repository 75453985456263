import {Component, Input} from '@angular/core';
import {VenteLabelPriceOptions} from '@features/dossier-bien/pricings/pricings.interfaces';
import Location from '@models/location/location.model';
import {IDossierBien} from '@models/bien/bien.interfaces';
import Vente from '@models/vente/vente.model';

@Component({selector: 'app-dossier-bien-label-price', templateUrl: 'dossier-bien.label-price.component.html'})
export class AppDossierBienLabelPriceComponent {
    private _dossierBien!: IDossierBien;
    private _options!: VenteLabelPriceOptions;

    @Input()
    set dossierBien(value: IDossierBien) {
        this._dossierBien = value;
    }

    get dossierBien(): IDossierBien {
        return this._dossierBien;
    }

    get location(): Location {
        return this._dossierBien as unknown as Location;
    }

    @Input()
    set options(value: VenteLabelPriceOptions) {
        this._options = value;
    }

    get vente(): Vente {
        return this._dossierBien as unknown as Vente;
    }

    get venteOptions(): VenteLabelPriceOptions {
        return this._options ;
    }
}
