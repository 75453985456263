import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import environment from '@env/environment';
import Demandeur from '@core/models/demandeur/demandeur.model';
import {ClientFactory} from '@core/models/client/client.factory';
import {IClientApi} from '@core/models/client/client.interfaces';
import Client from '@core/models/client/client.model';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private _clientFactory: ClientFactory;
    private _httpClient: HttpClient;
    private _currentClient!: Client;

    constructor(clientFactory: ClientFactory, httpClient: HttpClient) {
        this._clientFactory = clientFactory;
        this._httpClient = httpClient;
    }

    get currentClient(): Client {
        return this._currentClient;
    }

    authenticate(token: string): Observable<Client> {
        const xAuthToken = 'x-auth-token';

        if (!token) {
            return throwError(() => 'AuthenticationService.authenticate : No \'token defined.');
        }

        return this._httpClient.get<IClientApi>(`${environment.api.baseUrl}/espace-client/contact`, {headers: {[xAuthToken]: token}}).pipe(
            map(clientApi => this._clientFactory.create(clientApi)),
            tap(client => client.token = token),
            tap(client => this._currentClient = client),
            map(_ => this._currentClient),
        );
    }

    isConnected(): boolean {
        // @todo Passer ces vérification dans l'objet Client
        return this._currentClient instanceof Client && this._currentClient.demandeur instanceof Demandeur;
    }

    signOut(): void {
        this._currentClient = undefined!;
    }
}
