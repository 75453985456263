import {Injectable} from '@angular/core';
import {ICommuneApi} from '@core/models/commune/commune.interfaces';
import Commune from '@core/models/commune/commune.model';

@Injectable({providedIn: 'root'})
export class CommuneFactory {
    create(communeApi: ICommuneApi): Commune {
        const commune = new Commune();

        commune.isFranceMetropolitaine = communeApi.isFranceMetropolitaine;
        commune.nom = communeApi.nom;

        return commune;
    }
}
