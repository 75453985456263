import Phone from '@core/models/phone/phone.model';
import {IIdentity} from '@core/models/model.interfaces';

export default class Entreprise implements IIdentity {
    private _email: string;
    private _mobile: Phone;
    private _raisonSociale: string;
    private _telephone: Phone;

    get convivialName(): string {
        return this.raisonSociale;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get mobile(): Phone {
        return this._mobile;
    }

    set mobile(value: Phone) {
        this._mobile = value;
    }

    get raisonSociale(): string {
        return this._raisonSociale;
    }

    set raisonSociale(value: string) {
        this._raisonSociale = value;
    }

    get telephone(): Phone {
        return this._telephone;
    }

    set telephone(value: Phone) {
        this._telephone = value;
    }
}
