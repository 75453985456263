<div class="app-login">
    <div class="container">
        <h1>My Noty</h1>

        <mat-card>
            <mat-card-header><img [src]="urlMarianne"></mat-card-header>

            <mat-card-content>
                <h2>
                    <div [ngSwitch]="errorLogin?.status" class="information">
                        <div *ngSwitchCase="410">Le responsable de votre dossier a désactivé votre compte.</div>

                        <div *ngSwitchDefault>Impossible de se connecter sur votre espace ?</div>
                    </div>

                    <div class="contact">Contactez votre notaire.</div>
                </h2>
            </mat-card-content>
        </mat-card>
    </div>
</div>
