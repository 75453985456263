<div *ngIf="!!images" class="soq-carrousel" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
  <div class="elem-list" [style.height]="height || '100%'" [style.width]="width || '100%'">
    <div *ngFor="let image of images; index as i">
      <img [id]="'img-'+ i" *ngIf="showImg(i)" [ngClass]="{'active': i === currentIndex}"
           [src]="image +'?size=optimized'">
    </div>
  </div>

  <div class="elem-actions no-print" *ngIf="images.length > 1">
    <span class="prev" (click)="prev()"><fa-icon icon="arrow-circle-left" size="2x"></fa-icon></span>

    <span class="next" (click)="next()"><fa-icon icon="arrow-circle-right" size="2x"></fa-icon></span>
  </div>

  <div class="elem-dot no-print" *ngIf="images.length > 1">
    <span *ngFor="let item of images; let i = index" [ngClass]="{'active': i === currentIndex}"
          (click)="setCurrentIndex(i)"><fa-icon icon="circle"></fa-icon></span>
  </div>
</div>
