<svg id="svg3039" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.6 540">
  <defs>
    <style>
      .dpe-1, .dpe-14, .dpe-15 {
        fill: #fafafa;
      }

      .dpe-1, .dpe-2, .dpe-20 {
        stroke: #000;
      }

      .dpe-1, .dpe-2 {
        stroke-width: 1.5px;
      }

      .dpe-2, .dpe-20 {
        fill: none;
      }

      .dpe-14, .dpe-15, .dpe-18, .dpe-19, .dpe-3, .dpe-5, .dpe-6, .dpe-8 {
        isolation: isolate;
        font-family: Roboto, sans-serif;
      }

      .dpe-3 {
        font-size: 22px;
        font-weight: 700;
        font-style: italic;
      }

      .dpe-4 {
        fill: #319a31;
      }

      .dpe-15, .dpe-5 {
        font-size: 20px;
      }

      .dpe-14, .dpe-18, .dpe-6, .dpe-8 {
        font-size: 32px;
      }

      .dpe-6 {
        letter-spacing: -1px;
      }

      .dpe-7 {
        fill: #3c3;
      }

      .dpe-9 {
        fill: #cf3;
      }

      .dpe-10 {
        fill: #ff0;
      }

      .dpe-11 {
        fill: #fc0;
      }

      .dpe-12 {
        fill: #ff9a33;
      }

      .dpe-13 {
        fill: red;
      }

      .dpe-18 {
        fill: #fffcfc;
      }

      .dpe-19 {
        font-size: 16px;
      }

      .st0 {
        opacity: 0.48;
        stroke: #000000;
        stroke-miterlimit: 10;
      }

      .st1 {
        fill: #020000;
      }

      .st2 {
        fill: #FAFAFA;
      }

      .st3 {
        font-family: Roboto, sans-serif;
      }

      .st4 {
        font-size: 45.7543px;
      }
    </style>
  </defs>
  <title>DPE</title>
  <g id="Structure">
    <rect id="rect425q6" class="dpe-1" x="0.75" y="0.75" width="473.1" height="536"/>
    <path id="path4258" class="dpe-2" d="M371.7,1.4V536" transform="translate(-0.05 -0.05)"/>
  </g>
  <g id="Legende">
    <text class="dpe-3" transform="translate(5.55 514)">Logement énergivore</text>
    <text class="dpe-3" transform="translate(5.55 27.37)">Logement économe</text>
  </g>

  <g id="A">
    <path
      d="M6.57,42.1c21.9-.2,43.9-0.2,65.8-0.2,8.4,8.8,18.2,16.5,26.3,25.7-10.3,11.8-15,16.9-25.1,27.9-22.2,0-44.6-.1-66.9-0.1,0-53.3.1-.1-0.1-53.3h0Z"
      id="path3828" class="dpe-4" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-5" transform="translate(13.59 75.08)">≤</text>
    <text class="dpe-5" transform="translate(24.57 75.08)">50</text>
    <text class="dpe-6" transform="translate(59.17 78.39)">A</text>
  </g>
  <g id="B">
    <path
      d="M6.57,105.5c21.9-.2,83.9-0.2,105.8-0.2,8.4,8.8,18.2,16.5,26.3,25.7-10.3,11.8-15,16.9-25.1,27.9-22.2,0-84.6-.1-106.9-0.1,0-53.3.1-.1-0.1-53.3h0Z"
      id="path3828-9" class="dpe-7" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-8" transform="translate(98.31 141.68)">B</text>
    <text class="dpe-5" transform="translate(14.88 136.68)">{{bien.habitable ? '51 à 90' : '51 à 110'}}</text>
  </g>
  <g id="C">
    <path
      d="M6.57,168.9c19.5-.2,111.6-0.2,131.1-0.2,7.5,8.8,16.1,16.5,23.3,25.7-9.2,11.8-13.3,16.9-22.2,27.9-19.7,0-112.3-.1-132-0.1-0.1-53.3,0-.1-0.2-53.3h0Z"
      id="path3828-9-7" class="dpe-9" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-8" transform="translate(123.4 204.89) scale(0.89 1)">C</text>
    <text class="dpe-5" transform="translate(14.88 201.89)">{{bien.habitable ? '91 à 150' : '111 à 210'}}</text>
  </g>
  <g id="D">
    <path
      d="M6.57,232.3c19.5-.2,149.2-0.2,168.8-0.2,7.5,8.8,16.2,16.5,23.4,25.7-9.2,11.8-13.3,16.9-22.3,27.9-19.7,0-149.9-.1-169.7-0.1-0.1-53.3,0-.1-0.2-53.3h0Z"
      id="path3828-9-7-1" class="dpe-10" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-8" transform="translate(158.96 268.1) scale(0.89 1)">D</text>
    <text class="dpe-5" transform="translate(13.53 263.1)">{{bien.habitable ? '151 à 230' : '211 à 350'}}</text>
  </g>
  <g id="E">
    <path
      d="M6.57,295.7c19.8-.2,187.3-0.2,207-0.2,7.6,8.8,16.4,16.5,23.7,25.7-9.3,11.8-13.5,16.9-22.6,27.9L6.77,349c-0.1-53.3,0-.1-0.2-53.3h0Z"
      id="path3828-9-7-1-4" class="dpe-11" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-8" transform="translate(198.81 332.7) scale(0.9 1)">E</text>
    <text class="dpe-5" transform="translate(15.13 327.7)">{{bien.habitable ? '231 à 330' : '351 à 540'}}</text>
  </g>
  <g id="F">
    <path
      d="M6.57,359.1c19.8-.2,223.9-0.2,243.7-0.2,7.6,8.8,16.4,16.5,23.7,25.7-9.3,11.8-13.5,16.9-22.6,27.9l-244.6-.1c-0.1-53.3,0-.1-0.2-53.3h0Z"
      id="path3828-9-7-1-4-3" class="dpe-12" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-8" transform="translate(235.44 394.1) scale(0.9 1)">F</text>
    <text class="dpe-5" transform="translate(14.87 391.1)">{{bien.habitable ? '331 à 450' : '541 à 750'}}</text>
  </g>
  <g id="G">
    <path
      d="M6.57,422.4c19.7-.2,259-0.2,278.8-0.2,7.6,8.8,16.4,16.5,23.6,25.7-9.3,11.8-13.5,16.9-22.6,27.9l-279.7-.1c0-53.2.1,0-.1-53.3h0Z"
      id="path3828-9-7-1-4-3-1" class="dpe-13" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-14" transform="translate(271.94 459.18) scale(0.9 1)">G</text>
    <text class="dpe-15" transform="translate(14.62 454.18)">{{bien.habitable ? '> 450' : '> 750'}}</text>
  </g>

  <g *ngIf="bien.performanceEnergetique.consommation <= 50" id="APointeur">
    <path
      d="M470,42c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.2-.1,0,0.1-53.3h0Z"
      id="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 78.33)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 115.67)">kWh/m²/an</text>
    <line id="trait" class="dpe-20" x1="98.65" y1="67.45" x2="377.85" y2="67.45"/>
  </g>

  <g *ngIf="(bien.habitable && 50 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 90) ||
    (!bien.habitable && 50 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 110)"
     id="BPointeur">
    <path
      d="M470,105c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.3-.1-0.1.1-53.3h0Z"
      id="black-arrow-2" data-name="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 141.29)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 178.62)">kWh/m²/an</text>
    <line id="trait-2" data-name="trait" class="dpe-20" x1="138.65" y1="130.45" x2="377.85" y2="130.45"/>
  </g>

  <g *ngIf="(bien.habitable && 90 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 150) ||
    (!bien.habitable && 110 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 210)"
     id="CPointeur">
    <path
      d="M470,168.9c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.3-.1-0.1.1-53.3h0Z"
      id="black-arrow-3" data-name="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 205.17)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 242.51)">kWh/m²/an</text>
    <line id="trait-3" data-name="trait" class="dpe-20" x1="160.95" y1="194.35" x2="384.65" y2="194.35"/>
  </g>

  <g *ngIf="(bien.habitable && 150 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 230) ||
    (!bien.habitable && 210 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 350)"
     id="DPointeur">
    <path
      d="M470,232.3c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.3-.1-0.1.1-53.3h0Z"
      id="black-arrow-4" data-name="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 268.56)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 305.9)">kWh/m²/an</text>
    <line id="trait-4" data-name="trait" class="dpe-20" x1="198.85" y1="257.75" x2="384.65" y2="257.75"/>
  </g>

  <g *ngIf="(bien.habitable && 230 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 330) ||
    (!bien.habitable && 350 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 540)"
     id="EPointeur">
    <path
      d="M470,296c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.2-.1,0,0.1-53.3h0Z"
      id="black-arrow-5" data-name="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 332.31)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 369.65)">kWh/m²/an</text>
    <line id="trait-5" data-name="trait" class="dpe-20" x1="237.25" y1="321.45" x2="383.35" y2="321.45"/>
  </g>

  <g *ngIf="(bien.habitable && 330 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 450) ||
    (!bien.habitable && 540 < bien.performanceEnergetique.consommation && bien.performanceEnergetique.consommation <= 750)"
     id="FPointeur">
    <path
      d="M470,359.1c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.3-.1-0.1.1-53.3h0Z"
      id="black-arrow-6" data-name="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 395.35)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 432.69)">kWh/m²/an</text>
    <line id="trait-6" data-name="trait" class="dpe-20" x1="273.95" y1="384.55" x2="383.35" y2="384.55"/>
  </g>

  <g *ngIf="(bien.habitable && 450 < bien.performanceEnergetique.consommation) ||
    (!bien.habitable && 750 < bien.performanceEnergetique.consommation)" id="GPointeur">
    <path
      d="M470,422.5c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.3-.1-0.1.1-53.3h0Z"
      id="black-arrow-7" data-name="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 458.79)">{{ bien.performanceEnergetique.consommation }}</text>
    <text class="dpe-19" transform="translate(390.81 496.13)">kWh/m²/an</text>
    <line id="trait-7" data-name="trait" class="dpe-20" x1="308.95" y1="447.95" x2="383.35" y2="447.95"/>
  </g>

  <g *ngIf="!bien.performanceEnergetique.consommation" id="APointeur">
    <path
      d="M470,42c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.2-.1,0,0.1-53.3h0Z"
      id="black-arrow" transform="translate(-0.05 -0.05)"/>
    <text class="dpe-18" transform="translate(395.36 79.33)"> NC</text>
  </g>

  <rect *ngIf="!bien.performanceEnergetique.isDisponible()" x="-1.6" y="0.5" class="st0" width="477.8" height="540"/>
  <polygon *ngIf="!bien.performanceEnergetique.isDisponible()" class="st1"
           points="473.1,0.7 473.8,85.3 0,513.8 0,434.8 "/>
  <text *ngIf="!bien.performanceEnergetique.isDisponible()" class="st2 st3 st4"
        transform="matrix(0.7428 -0.6784 0.6744 0.7384 98.3999 409.7432)">{{statutLabel}}</text>
</svg>
