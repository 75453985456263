export default class BienPerformanceEnergetique {
    static readonly V1toV2 = '2021-07-01T00:00:00';
    private _consommation: number;
    private _consommationClasse: string;
    private _coutMax: number;
    private _coutMin: number;
    private _date: string;
    private _dateReference: string;
    private _emissions: number;
    private _emissionsClasse: string;
    private _statutDpe: string;

    get consommation(): number {
        return this._consommation;
    }

    set consommation(value: number) {
        this._consommation = value;
    }

    get consommationClasse(): string {
        return this._consommationClasse;
    }

    set consommationClasse(value: string) {
        this._consommationClasse = value;
    }

    get coutMax(): number {
        return this._coutMax;
    }

    set coutMax(value: number) {
        this._coutMax = value;
    }

    get coutMin(): number {
        return this._coutMin;
    }

    set coutMin(value: number) {
        this._coutMin = value;
    }

    get date(): string {
        return this._date;
    }

    set date(value: string) {
        this._date = value;
    }

    get dateReference(): string {
        return this._dateReference;
    }

    set dateReference(value: string) {
        this._dateReference = value;
    }

    get emissions(): number {
        return this._emissions;
    }

    set emissions(value: number) {
        this._emissions = value;
    }

    get emissionsClasse(): string {
        return this._emissionsClasse;
    }

    set emissionsClasse(value: string) {
        this._emissionsClasse = value;
    }

    get statutDpe(): string {
        return this._statutDpe;
    }

    set statutDpe(value: string) {
        this._statutDpe = value;
    }

    isDisponible(): boolean {
        return this._statutDpe === 'disponible';
    }

    isV1ByDate(): boolean {
        return !this.date || new Date(this.date) < new Date(BienPerformanceEnergetique.V1toV2);
    }
}
