// @todo Valider l'utilisation de tous les attributs
export default class Phone {
    private _e164!: string;

    get convivial(): string {
        return this.e164?.replace('+33', '0').replace(/(.{2})/g,'$1 ').slice(0, -1);
    }

    get e164(): string {
        return this._e164;
    }

    set e164(value: string) {
        this._e164 = value;
    }
}
