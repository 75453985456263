<!-- @todo Régler le trait entre le graphe et la flèche qui est trop à droite -->

<svg id="svg3039" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.6 540">
  <defs>
    <style>
      .cls-15, .cls-16 {
        fill: #fafafa;
      }

      .cls-15, .cls-16, .cls-19, .cls-20, .cls-5, .cls-6, .cls-8 {
        isolation: isolate;
        font-family: Roboto, sans-serif;
      }

      .cls-4 {
        fill: #dacee7;
      }

      .cls-16, .cls-5 {
        font-size: 20px;
      }

      .cls-15, .cls-19, .cls-6, .cls-8 {
        font-size: 32px;
      }

      .cls-10, .cls-6 {
        letter-spacing: -1px;
      }

      .cls-7 {
        fill: #e1c2f8;
      }

      .cls-9 {
        fill: #d4a9f5;
      }

      .cls-11 {
        fill: #cb95f3;
      }

      .cls-12 {
        fill: #af1adc;
      }

      .cls-13 {
        fill: #9a00cf;
      }

      .cls-14 {
        fill: #8600cf;
      }

      .cls-19 {
        fill: #fffcfc;
      }

      .cls-20 {
        font-size: 16px;
      }

      .st0 {
        opacity: 0.48;
        stroke: #000000;
        stroke-miterlimit: 10;
      }

      .st1 {
        fill: #020000;
      }

      .st2 {
        fill: #FAFAFA;
      }

      .st3 {
        font-family: Roboto, sans-serif;
      }

      .st4 {
        font-size: 45.7543px;
      }
    </style>
  </defs>
  <title>GES</title>

  <g id="Structure">
    <rect id="rect425q6" class="dpe-1" x="0.75" y="0.75" width="473.1" height="536"/>
    <path id="path4258" class="dpe-2" d="M371.7,1.4V536" transform="translate(-30.05 -0.05)"/>
  </g>
  <g id="Legende">
    <text class="dpe-3" transform="translate(5.55 514)">Forte émission de GES</text>
    <text class="dpe-3" transform="translate(5.55 27.37)">Faible émission de GES</text>
  </g>

  <g id="A">
    <rect class="cls-4" x="13.29" y="41.24" width="82.83" height="53.66"/>
    <text class="cls-5" transform="translate(23.78 73.58)">≤ 5</text>
    <text class="cls-6" transform="translate(72.36 75.58)">A</text>
  </g>
  <g id="B">
    <rect class="cls-7" x="13.29" y="104.12" width="115.33" height="54.38"/>
    <text class="cls-8" transform="translate(100.51 138.79)">B</text>
    <text class="cls-5" transform="translate(22.78 137.18)">{{bien.habitable ? '6 à 10' : '6 à 15'}}</text>
  </g>
  <g id="C">
    <rect class="cls-9" x="13.29" y="168.18" width="139.98" height="53.96"/>
    <text class="cls-8" transform="translate(123.84 204.09)">C</text>
    <text class="cls-5" transform="translate(22.78 199.39)">
      <tspan class="cls-10">1</tspan> <tspan x="9.64" y="0">{{bien.habitable ? '11 à 20' : '16 à 30'}}</tspan>
    </text>
  </g>
  <g id="D">
    <rect class="cls-11" x="13.29" y="231.6" width="170.67" height="53.49"/>
    <text class="cls-8" transform="translate(153.26 265.6)">D</text>
    <text class="cls-5" transform="translate(22.95 262.8)">{{bien.habitable ? '21 à 35' : '31 à 60'}}</text>
  </g>
  <g id="E">
    <rect class="cls-12" x="13.29" y="294.82" width="202.76" height="54.38"/>
    <text class="cls-8" transform="translate(183.96 331.2)">E</text>
    <text class="cls-5" transform="translate(22.78 326.01)">{{bien.habitable ? '36 à 55' : '61 à 100'}}</text>
  </g>
  <g id="F">
    <rect class="cls-13" x="13.29" y="358.24" width="237.14" height="54.38"/>
    <text class="cls-8" transform="translate(221 394.41)">F</text>
    <text class="cls-5" transform="translate(22.78 389.58)">{{bien.habitable ? '56 à 80' : '101 à 145'}}</text>
  </g>
  <g id="G">
    <rect class="cls-14" x="13.29" y="422.01" width="268.2" height="53.45"/>
    <text class="cls-15" transform="translate(250.42 456.97)">G</text>
    <text class="cls-16" transform="translate(22.95 453.53)">{{bien.habitable ? '> 80' : '> 145'}}</text>
  </g>

  <g *ngIf="bien.performanceEnergetique.emissions <= 5" id="APointeur">
    <path d="M440,42.88c-22-.2-43.9-0.2-65.9-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.2,0,0,.2-53.3h0Z"
          id="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(367.33 78.72)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.79 119.01)">kgéqCO2/m².an</text>
    <line id="trait" class="dpe-20" x1="98.65" y1="67.45" x2="377.85" y2="67.45"/>
  </g>

  <g *ngIf="(bien.habitable && 5 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 10) ||
    (!bien.habitable && 5 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 15)"
     id="BPointeur">
    <path d="M440,106c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0-53.3-.1,0,0.1-53.3h0Z"
          id="path3828-4-2" data-name="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(366.33 141.79)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.79 181.01)">kgéqCO2/m².an</text>
    <line id="trait-2" data-name="trait" class="dpe-20" x1="138.65" y1="130.45" x2="377.85" y2="130.45"/>
  </g>

  <g *ngIf="(bien.habitable && 10 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 20) ||
    (!bien.habitable && 15 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 30)"
     id="CPointeur">
    <path d="M440,170.28c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0-53.3-.1,0,0.1-53.3h0Z"
          id="path3828-4-3" data-name="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(367.33 206.08)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.89 245.01)">kgéqCO2/m².an</text>
    <line id="trait-3" data-name="trait" class="dpe-20" x1="160.95" y1="194.35" x2="384.65" y2="194.35"/>
  </g>

  <g *ngIf="(bien.habitable && 20 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 35) ||
    (!bien.habitable && 30 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 60)"
     id="DPointeur">
    <path d="M440,231.38c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0-53.2-.1,0,0.1-53.3h0Z"
          id="path3828-4-4" data-name="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(366.33 267.21)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.79 307.01)">kgéqCO2/m².an</text>
    <line id="trait-4" data-name="trait" class="dpe-20" x1="198.85" y1="257.75" x2="384.65" y2="257.75"/>
  </g>

  <g *ngIf="(bien.habitable && 35 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 55) ||
    (!bien.habitable && 60 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 100)"
     id="EPointeur">
    <path d="M440,297.68c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0-53.3-.1-0.1.1-53.3h0Z"
          id="path3828-4-5" data-name="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(367.33 333.46)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.79 372.01)">kgéqCO2/m².an</text>
    <line id="trait-5" data-name="trait" class="dpe-20" x1="237.25" y1="321.45" x2="383.35" y2="321.45"/>
  </g>

  <g *ngIf="(bien.habitable && 55 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 80) ||
    (!bien.habitable && 100 < bien.performanceEnergetique.emissions && bien.performanceEnergetique.emissions <= 145)"
     id="FPointeur">
    <path d="M440,360.68c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0-53.3-.1-0.1.1-53.3h0Z"
          id="path3828-4-6" data-name="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(367.33 396.43)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.79 435.01)">kgéqCO2/m².an</text>
    <line id="trait-6" data-name="trait" class="dpe-20" x1="273.95" y1="384.55" x2="383.35" y2="384.55"/>
  </g>

  <g *ngIf="(bien.habitable && 80 < bien.performanceEnergetique.emissions) ||
    (!bien.habitable && 145 < bien.performanceEnergetique.emissions)" id="GPointeur">
    <path d="M440,422.76c-21.9-.2-43.9-0.2-65.8-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0-53.3-.1-0.1.1-53.3h0Z"
          id="path3828-4-7" data-name="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(367.33 458.51)">{{ bien.performanceEnergetique.emissions }}</text>
    <text class="cls-20" transform="translate(347.79 497.1)">kgéqCO2/m².an</text>
    <line id="trait-7" data-name="trait" class="dpe-20" x1="308.95" y1="447.95" x2="383.35" y2="447.95"/>
  </g>

  <g *ngIf="!bien.performanceEnergetique.emissions" id="APointeur">
    <path d="M440,42.88c-22-.2-43.9-0.2-65.9-0.2-8.4,8.8-18.2,16.5-26.3,25.7,10.3,11.8,15,16.9,25.1,27.9,22.2,0,44.6-.1,66.9-0.1,0.1-53.2,0,0,.2-53.3h0Z"
          id="path3828-4" transform="translate(-0.05 -0.55)"/>
    <text class="cls-19" transform="translate(367.33 79.72)"> NC</text>
  </g>

  <rect *ngIf="!bien.performanceEnergetique.isDisponible()" x="-1.6" y="0.5" class="st0" width="477.8" height="540"/>
  <polygon *ngIf="!bien.performanceEnergetique.isDisponible()" class="st1"
           points="473.1,0.7 473.8,85.3 0,513.8 0,434.8 "/>
  <text *ngIf="!bien.performanceEnergetique.isDisponible()"
        transform="matrix(0.7428 -0.6784 0.6744 0.7384 98.3999 409.7432)" class="st2 st3 st4">{{statutLabel}}</text>
</svg>
