import AOldDossier from '@app/old_class/dossier.abstract';
import LocationPricing from '@models/location/pricing/location-pricing.model';
import {IDossierBien} from '@models/bien/bien.interfaces';

// @todo Supprimer l'utilisation de AOldDossier
// @todo Valider l'utilisation de tous les attributs
export default class Location extends AOldDossier implements IDossierBien {
    private _dateDisponibilite!: string;
    private _meuble!: boolean;
    private _pricing!: LocationPricing;

    get dateDisponibilite(): string {
        return this._dateDisponibilite;
    }

    set dateDisponibilite(value: string) {
        this._dateDisponibilite = value;
    }

    get meuble(): boolean {
        return this._meuble;
    }

    set meuble(value: boolean) {
        this._meuble = value;
    }

    get pricing(): LocationPricing {
        return this._pricing;
    }

    set pricing(value: LocationPricing) {
        this._pricing = value;
    }

    get typeDossier(): string {
        return 'location';
    }
}
