import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import environment from '@env/environment';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import {ResultsCollectionFactory} from '@app/old_factories/results-collection.factory';
import {UtilsService} from '@app/old_services/utils.service';
import ResultsCollection from '@app/old_class/results-collection';
import Result from '@app/old_class/result';

@Injectable({providedIn: 'root'})
export class ResultsService {
    private _authenticationService: AuthenticationService;
    private _httpClient: HttpClient;
    private _resultsCollectionFactory: ResultsCollectionFactory;
    private _utilsService: UtilsService;
    private _errorResult!: Error;
    private _resultsCollection!: ResultsCollection;

    constructor(authenticationService: AuthenticationService,
                httpClient: HttpClient,
                resultsCollectionFactory: ResultsCollectionFactory,
                utilsService: UtilsService) {
        this._authenticationService = authenticationService;
        this._httpClient = httpClient;
        this._resultsCollectionFactory = resultsCollectionFactory;
        this._utilsService = utilsService;
    }

    get errorResult(): Error {
        return this._errorResult;
    }

    set errorResult(value: Error) {
        this._errorResult = value;
    }

    getAll(): Observable<ResultsCollection> {
        return this._httpClient.get(`${environment.api.baseUrl}/espace-client/${this._authenticationService.currentClient.demandeur.id}/resultats`, {
            headers: this._utilsService.getCommonHeaders()
        }).pipe(
            map(results => this._resultsCollection = this._resultsCollectionFactory.createFromJson(results))
        );
    }

    getOneById(id: number): Observable<Result> {
        let resultsCollection$: Observable<ResultsCollection>;

        if (this._resultsCollection instanceof ResultsCollection && this._resultsCollection.total > 0) {
            resultsCollection$ = of(this._resultsCollection);
        } else {
            resultsCollection$ = this.getAll();
        }

        return resultsCollection$.pipe(map((resultsCollection: ResultsCollection) => {
            let currentResult: Result = undefined!;

            if (resultsCollection.total > 0) {
                resultsCollection.collection.map(result => {
                    if (result.id === id) {
                        currentResult = result;
                    }
                });
            }

            return currentResult;
        }), map((result: Result) => {
            if (result === undefined) {
                this._errorResult = new Error(`Résultat '${id}' introuvable.`);
                throw this._errorResult;
            }

            return result;
        }));
    }
}
