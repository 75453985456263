export default class Commune {
    private _isFranceMetropolitaine!: boolean;
    private _nom!: string;

    get isFranceMetropolitaine(): boolean {
        return this._isFranceMetropolitaine;
    }

    set isFranceMetropolitaine(value: boolean) {
        this._isFranceMetropolitaine = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        this._nom = value;
    }
}
