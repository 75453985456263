import {Component, Input} from '@angular/core';
import Location from '@models/location/location.model';
import {IDossierBien} from '@models/bien/bien.interfaces';
import Vente from '@models/vente/vente.model';

@Component({selector: 'app-dossier-bien-pricing', templateUrl: 'dossier-bien.pricing.component.html'})
export class AppDossierBienPricingComponent {
    private _dossierBien!: IDossierBien;

    @Input()
    set dossierBien(value: IDossierBien) {
        this._dossierBien = value;
    }

    get dossierBien(): IDossierBien {
        return this._dossierBien;
    }

    get location(): Location {
        return this._dossierBien as unknown as Location;
    }

    get vente(): Vente {
        return this._dossierBien as unknown as Vente;
    }
}
