import Commune from '@core/models/commune/commune.model';
import Etude from '@core/models/etude/etude.model';

// @todo Valider l'utilisation de tous les attributs
export default class Site {
    private _adresse!: string;
    private _codePostal!: string;
    private _commune!: Commune;
    private _etude!: Etude;

    get adresse(): string {
        return this._adresse;
    }

    set adresse(value: string) {
        this._adresse = value;
    }

    get codePostal(): string {
        return this._codePostal;
    }

    set codePostal(value: string) {
        this._codePostal = value;
    }

    get commune(): Commune {
        return this._commune;
    }

    set commune(value: Commune) {
        this._commune = value;
    }

    get etude(): Etude {
        return this._etude;
    }

    set etude(value: Etude) {
        this._etude = value;
    }
}
