import {Injectable} from '@angular/core';
import Financeur from '@models/financeurs/financeur/financeur.model';
import {IFinanceurApi} from '@models/financeurs/financeur/financeur.interfaces';
import Vente from '@models/vente/vente.model';
import {Observable} from 'rxjs';
import {FinanceursApiService} from '@models/financeurs/financeurs.api.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FinanceurFactory {
    private _financeursApiService: FinanceursApiService;

    constructor(financeursApiService: FinanceursApiService) {
        this._financeursApiService = financeursApiService;
    }

    create(financeurApi: IFinanceurApi): Financeur {
        const financeur = new Financeur(financeurApi.uuid);

        financeur.cgu = financeurApi.cgu;
        financeur.nom = financeurApi.nom;

        return financeur;
    }

    addDemande$(financeur: Financeur, vente: Vente): Observable<void> {
        return this._financeursApiService.addDemande$(financeur.uuid, {venteUuid: vente.uuid}).pipe(map(_ => undefined as void));
    }
}
