<section fxFlexFill
         fxLayout="row"
         fxLayout.sm="column"
         fxLayout.print="xs"
         fxLayout.xs="column">
  <div fxFlex="70" class="left-panel img-panel">
    <app-dossier-bien-photos [dossier]="dossierBien" [photosIdx]="photosIdx" (getPhotosIdx)="getPhotosIdxCB($event)">
    </app-dossier-bien-photos>
  </div>

  <div fxFlex="30" class="right-panel">
    <app-pricing-dossier [dossierBien]="dossierBien"></app-pricing-dossier>

    <table>
      <tbody>
      <tr>
        <td>Surface terrain</td>
        <td [ngSwitch]="!!dossierBien.bien.surfaceHabitable">
          <span *ngSwitchCase="true">{{dossierBien.bien.surfaceHabitable | number}}
            m<sup>2</sup></span>
          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>
      <tr>
        <td>Surface cadastrale</td>
        <td [ngSwitch]="!!dossierBien.bien.surfaceCadastrale">
          <span *ngSwitchCase="true">{{dossierBien.bien.surfaceCadastrale | number}}
            m<sup>2</sup></span>
          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>

      <tr *ngIf="dossierBien.typeDossier === 'location'">
        <td>Disponibilité</td>
        <td [ngSwitch]="!!location.dateDisponibilite">
          <span *ngSwitchCase="true">{{location.dateDisponibilite | date}}</span>

          <span *ngSwitchDefault>N.C.</span>
        </td>
      </tr>

      <tr *ngIf="dossierBien.typeDossier === 'location' && location.meuble === true">
        <td>Meublé</td>
        <td>OUI</td>
      </tr>
      </tbody>
    </table>
  </div>
</section>

<section><p [innerHTML]="dossierBien.descriptif | newline" class="description"></p></section>
