import DictionaryItem from '@models/dictionary/item/dictionary-item.model';

export default class LocationPricing {
    private _charges!: number;
    private _chargesIncluses!: boolean;
    private _depotGarantie!: number;
    private _etatLieuxChargeLocataire!: number;
    private _honoRedacChargeLocataire!: number;
    private _loyer!: number;
    private _loyerBase!: number;
    private _loyerComplement!: number;
    private _loyerEncadrement!: boolean;
    private _loyerReferenceMajore!: number;
    private _periodicite!: DictionaryItem;

    get charges(): number {
        return this._charges;
    }

    set charges(value: number) {
        this._charges = value;
    }

    get chargesIncluses(): boolean {
        return this._chargesIncluses;
    }

    set chargesIncluses(value: boolean) {
        this._chargesIncluses = value;
    }

    get depotGarantie(): number {
        return this._depotGarantie;
    }

    set depotGarantie(value: number) {
        this._depotGarantie = value;
    }

    get etatLieuxChargeLocataire(): number {
        return this._etatLieuxChargeLocataire;
    }

    set etatLieuxChargeLocataire(value: number) {
        this._etatLieuxChargeLocataire = value;
    }

    get honoRedacChargeLocataire(): number {
        return this._honoRedacChargeLocataire;
    }

    set honoRedacChargeLocataire(value: number) {
        this._honoRedacChargeLocataire = value;
    }

    get loyer(): number {
        return this._loyer;
    }

    set loyer(value: number) {
        this._loyer = value;
    }

    get loyerBase(): number {
        return this._loyerBase;
    }

    set loyerBase(value: number) {
        this._loyerBase = value;
    }

    get loyerComplement(): number {
        return this._loyerComplement;
    }

    set loyerComplement(value: number) {
        this._loyerComplement = value;
    }

    get loyerEncadrement(): boolean {
        return this._loyerEncadrement;
    }

    set loyerEncadrement(value: boolean) {
        this._loyerEncadrement = value;
    }

    get loyerReferenceMajore(): number {
        return this._loyerReferenceMajore;
    }

    set loyerReferenceMajore(value: number) {
        this._loyerReferenceMajore = value;
    }

    get periodicite(): DictionaryItem {
        return this._periodicite;
    }

    set periodicite(value: DictionaryItem) {
        this._periodicite = value;
    }
}
