import {PhoneFactory} from '@core/models/phone/phone.factory';
import {Injectable} from '@angular/core';
import Entreprise from '@core/models/entreprise/entreprise.model';
import {IEntrepriseApi} from '@core/models/entreprise/entreprise.interfaces';

@Injectable({providedIn: 'root'})
export class EntrepriseFactory {
    private _phoneFactory: PhoneFactory;

    constructor(phoneFactory: PhoneFactory) {
        this._phoneFactory = phoneFactory;
    }

    create(entrepriseApi: IEntrepriseApi): Entreprise {
        const entreprise = new Entreprise();

        entreprise.email = entrepriseApi.email;
        entreprise.mobile = this._phoneFactory.create(entrepriseApi.mobile);
        entreprise.raisonSociale = entrepriseApi.raisonSociale;
        entreprise.telephone = this._phoneFactory.create(entrepriseApi.telephone);

        return entreprise;
    }
}
