import {Injectable} from '@angular/core';
import LocationPricing from '@models/location/pricing/location-pricing.model';
import {ILocationApi} from '@models/location/location.interfaces';
import {DictionaryItemFactory} from '@models/dictionary/item/dictionary-item.factory';

@Injectable({providedIn: 'root'})
export class LocationPricingFactory {
    private _dictionaryItemFactory: DictionaryItemFactory;

    constructor(dictionaryItemFactory: DictionaryItemFactory) {
        this._dictionaryItemFactory = dictionaryItemFactory;
    }

    createFromLocationApi(locationApi: ILocationApi): LocationPricing {
        const locationPricing = new LocationPricing();

        locationPricing.charges = locationApi.montantCharge;
        locationPricing.chargesIncluses = locationApi.chargesIncluses;
        locationPricing.depotGarantie = locationApi.montantDepotGarantie;
        locationPricing.etatLieuxChargeLocataire = locationApi.etatLieuxChargeLocataire;
        locationPricing.honoRedacChargeLocataire = locationApi.honoRedacChargeLocataire;
        locationPricing.loyer = locationApi.loyer;
        locationPricing.loyerBase = locationApi.loyerBase;
        locationPricing.loyerComplement = locationApi.loyerComplement;
        locationPricing.loyerEncadrement = locationApi.loyerEncadrement;
        locationPricing.loyerReferenceMajore = locationApi.loyerReferenceMajore;
        locationPricing.periodicite = this._dictionaryItemFactory.create({abreviation: 'mois', code: 'mois', label: 'Mensuelle'});

        return locationPricing;
    }
}
