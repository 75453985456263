import VentePricingAbstract from '@models/vente/pricing/vente.pricing.abstract';
import {IVentePricingTypePrix} from '@models/vente/pricing/vente.pricing.interfaces';

// @todo Valider l'utilisation de tous les attributs
export default class VenteViagerPricing extends VentePricingAbstract {
    private _bouquetHNI!: number;
    private _bouquetNV!: number;
    private _rente!: number;
    private _rentePeriodicite!: string;
    private _valeurBien!: number;

    get pourcentageHonoraires(): number {
        return Math.round(this.honoraires / this.valeurBien * 100 * 100) / 100;
    }

    get priceHNI(): number {
        return this.bouquetHNI;
    }

    get priceNV(): number {
        return this.bouquetNV;
    }

    get bouquetHNI(): number {
        return this._bouquetHNI;
    }

    set bouquetHNI(value: number) {
        this._bouquetHNI = value;
    }

    get bouquetNV(): number {
        return this._bouquetNV;
    }

    set bouquetNV(value: number) {
        this._bouquetNV = value;
    }

    get rente(): number {
        return this._rente;
    }

    set rente(value: number) {
        this._rente = value;
    }

    get rentePeriodicite(): string {
        return this._rentePeriodicite;
    }

    set rentePeriodicite(value: string) {
        this._rentePeriodicite = value;
    }

    get valeurBien(): number {
        return this._valeurBien;
    }

    set valeurBien(value: number) {
        this._valeurBien = value;
    }

    get TYPE_PRICE(): IVentePricingTypePrix {
        // @todo Supprimer les "as"
        return {abreviation: 'Bouquet', code: 'bouquet', label: 'Bouquet', no_price: 'Pas de bouquet saisi'} as IVentePricingTypePrix;
    }
}
