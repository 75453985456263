import {
    Component,
    ComponentFactoryResolver, EventEmitter,
    Input,
    OnInit, Output, Type,
    ViewContainerRef
} from '@angular/core';
import {IDossierBien} from '@models/bien/bien.interfaces';
import {MaisonDetailsComponent} from '@app/old_components/dossier-details/maison-details/maison-details.component';
import {
    AppartementDetailsComponent
} from '@app/old_components/dossier-details/appartement-details/appartement-details.component';
import {
    ImmeubleDetailsComponent
} from '@app/old_components/dossier-details/immeuble-details/immeuble-details.component';
import {BureauxDetailsComponent} from '@app/old_components/dossier-details/bureaux-details/bureaux-details.component';
import {
    LocauxCommerciauxDetailsComponent
} from '@app/old_components/dossier-details/locaux-commerciaux-details/locaux-commerciaux-details.component';
import {GarageDetailsComponent} from '@app/old_components/dossier-details/garage-details/garage-details.component';
import {
    TerrainABatirDetailsComponent
} from '@app/old_components/dossier-details/terrain-a-batir-details/terrain-a-batir-details.component';
import {
    AutreTerrainDetailsComponent
} from '@app/old_components/dossier-details/autre-terrain-details/autre-terrain-details.component';
import {AutreDetailsComponent} from '@app/old_components/dossier-details/autre-details/autre-details.component';

interface ComponentDossierBien { dossierBien: IDossierBien; getPhotosIdx: EventEmitter<number>; photosIdx: number }

@Component({selector: 'app-dossier-details-container', template: ''})
export class DossierDetailsContainerComponent implements OnInit {
    @Output() getPhotosIdx = new EventEmitter<number>();
    private _dossierBien!: IDossierBien;
    private _photosIdx = 0;
    private _componentFactoryResolver: ComponentFactoryResolver;
    private _viewContainerRef: ViewContainerRef;

    // @todo Remettre à jour le yarn.lock
    constructor(componentFactoryResolver: ComponentFactoryResolver, viewContainerRef: ViewContainerRef) {
        this._componentFactoryResolver = componentFactoryResolver;
        this._viewContainerRef = viewContainerRef;
    }

    @Input()
    set dossierBien(value: IDossierBien) {
        this._dossierBien = value;
    }

    get photosIdx(): number {
        return this._photosIdx;
    }

    @Input()
    set photosIdx(value: number) {
        this._photosIdx = value;
    }

    ngOnInit(): void {
        let component: Type<ComponentDossierBien> = undefined!;

        switch (this._dossierBien.bien.natureCode) {
            case 'MAISON':
                component = MaisonDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'APPARTEMENT':
                component = AppartementDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'IMMEUBLE':
                component = ImmeubleDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'BUREAUX':
                component = BureauxDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'LOCAUX_COMMERCIAUX':
                component = LocauxCommerciauxDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'GARAGE':
                component = GarageDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'TERRAIN_A_BATIR':
                component = TerrainABatirDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'AUTRE_TERRAIN':
                component = AutreTerrainDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
            case 'AUTRE':
                component = AutreDetailsComponent as unknown as Type<ComponentDossierBien>;
                break;
        }

        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(component);
        const componentRefInstance = this._viewContainerRef.createComponent(componentFactory).instance;

        componentRefInstance.dossierBien = this._dossierBien;
        componentRefInstance.getPhotosIdx = this.getPhotosIdx;
        componentRefInstance.photosIdx = this.photosIdx;
    }
}
