import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AuthenticationService} from '@core/routing/services/authentication.service';
import {IIdentity} from '@core/models/model.interfaces';
import Vente from '@models/vente/vente.model';
import {CFinanceursFactory} from '@models/financeurs/collection/financeurs.collection.factory';
import CFinanceurs from '@models/financeurs/collection/financeurs.collection.model';
import Financeur from '@models/financeurs/financeur/financeur.model';
import {FinanceurFactory} from '@models/financeurs/financeur/financeur.factory';

@Component({
    selector: 'app-demande-etude-financement',
    templateUrl: 'demande-etude-financement.component.html',
    styleUrls: ['demande-etude-financement.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DemandeEtudeFinancementComponent implements OnInit {
    private _authenticationService: AuthenticationService;
    private _cFinanceursFactory: CFinanceursFactory;
    private _domSanitizer: DomSanitizer;
    private _financeurFactory: FinanceurFactory;
    private _matDialogRef: MatDialogRef<DemandeEtudeFinancementComponent>;
    private _cFinanceurs!: CFinanceurs;
    private _checkConfirmation = false;
    private _financeurSelected!: Financeur;
    private _identity!: IIdentity;
    private _trustedCGU!: SafeHtml;
    private _vente: Vente;

    constructor(authenticationService: AuthenticationService,
                cFinanceursFactory: CFinanceursFactory,
                domSanitizer: DomSanitizer,
                financeurFactory: FinanceurFactory,
                @Inject(MAT_DIALOG_DATA) matDialogData: { dossier: Vente },
                matDialogRef: MatDialogRef<DemandeEtudeFinancementComponent>) {
        this._authenticationService = authenticationService;
        this._cFinanceursFactory = cFinanceursFactory;
        this._domSanitizer = domSanitizer;
        this._financeurFactory = financeurFactory;
        this._matDialogRef = matDialogRef;
        this._vente = matDialogData.dossier;
    }

    get cFinanceurs(): CFinanceurs {
        return this._cFinanceurs;
    }

    get checkConfirmation(): boolean {
        return this._checkConfirmation;
    }

    set checkConfirmation(value: boolean) {
        this._checkConfirmation = value;
    }

    get financeurSelected(): Financeur {
        return this._financeurSelected;
    }

    set financeurSelected(financeur: Financeur) {
        this._financeurSelected = financeur;
    }

    get identity(): IIdentity {
        return this._identity;
    }

    get trustedCGU(): SafeHtml {
        return this._trustedCGU;
    }

    ngOnInit(): void {
        this._identity = this._authenticationService.currentClient.identity;
        // @todo Faire le tour des méthodes qui renvoient un Observable pour ajouter un $
        // @todo Ajouter un take(1) quand nécessaire
        this._cFinanceursFactory.get$().subscribe((financeursCollection: CFinanceurs) => {
            this._cFinanceurs = financeursCollection;
            if (this.cFinanceurs.total === 1) {
                this.financeurSelected = this.cFinanceurs.results[0];
                this.onSelectFinanceur();
            }
        });
    }

    cancel(): void {
        this._matDialogRef.close();
    }

    onSelectFinanceur(): void {
        this._trustedCGU = this._domSanitizer.bypassSecurityTrustHtml(this.financeurSelected.cgu);
    }

    send(): void {
        if (!this.financeurSelected || !this.checkConfirmation) {
            return;
        }

        this._financeurFactory.addDemande$(this.financeurSelected, this._vente).subscribe(_ => this._matDialogRef.close('send'));
    }
}
