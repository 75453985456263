<div class="relative">
  <div *ngIf="codeStatutDpe !== 'disponible'" class="z-10 bg-black bg-opacity-50 absolute inset-0">
    <div class="h-full w-full">
      <div class="flex align-center justify-center h-full w-full transform -rotate-25">
        <div class="bg-black py-4 px-8 text-4xl text-center font-bold text-white">DPE {{labelStatut}}</div>
      </div>
    </div>
  </div>

  <div class="flex">
    <app-diag-graphe-v2-consommation [codeStatutDpe]="codeStatutDpe" [consommationClasse]="consommationClasse"
                                     [consommation]="consommation" [emissions]="emissions"
                                     [ngClass]="options.grapheEmissionsVisibility ? 'w-3/5' : 'w-full'">
    </app-diag-graphe-v2-consommation>

    <app-diag-graphe-v2-emissions *ngIf="options.grapheEmissionsVisibility" [codeStatutDpe]="codeStatutDpe"
                                  [emissionsClasse]="emissionsClasse" [emissions]="emissions" class="w-2/5">
    </app-diag-graphe-v2-emissions>
  </div>
</div>

<div *ngIf="options.estimationCoutsVisibility && (coutMin || coutMax)" class="w-full text-center">
  <div class="mb-4">
    Coûts estimés d’énergie

    <ng-container *ngIf="coutMin && !coutMax">
      à partir de <span class="px-4 text-4xl font-bold">{{coutMin | appCurrency}}</span>
    </ng-container>

    <ng-container *ngIf="coutMin && coutMax">
      entre <span class="px-4 text-4xl font-bold">{{coutMin | appCurrency}}</span> et
      <span class="px-4 text-4xl font-bold">{{coutMax | appCurrency}}</span>
    </ng-container>

    <ng-container *ngIf="!coutMin && coutMax">
      jusqu'à <span class="px-4 text-4xl font-bold">{{coutMax | appCurrency}}</span>
    </ng-container>

    par an

    <ng-container *ngIf="dateReference">
      indexés au {{dateReference | date: 'longDate'}} (abonnements compris)
    </ng-container>
  </div>
</div>
